'use client'

import Script from 'next/script'

export default () => {
    return (
        <Script
            id="klaviyo"
            strategy="afterInteractive"
            src="https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=SJhBec"
        />
    )
}
