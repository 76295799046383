'use client'

import styles from './StatsSection.module.scss'
import classNames from 'classnames/bind'
import Title from '@/components/Elements/Title/Title'
import Stat from '@/components/Elements/Stat/Stat'
import SectionWrap from '@/components/Layout/SectionWrap/SectionWrap'
import useIntersectionObserver from '@/components/Helpers/UseIntersectionObserver/UseIntersectionObserver'

import { useRef, useState } from 'react'

const classes = classNames.bind(styles)

const StatsSection = ({ attributes }) => {
    const { content, stats, styling } = attributes

    const [loadStats, setLoadStats] = useState(false)

    const sectionRef = useRef(null)

    useIntersectionObserver({
        refs: [sectionRef],
        callback: ({ isIntersecting }) => {
            if (isIntersecting) {
                setTimeout(() => {
                    setLoadStats(true)
                }, 500)
            }
        },
        options: {
            // a threshold of 0 will trigger our IntersectionObserver at the
            // base of the element – a threshold of 1 would trigger at the top.
            threshold: [0.2],
        },
    })

    return (
        <SectionWrap styling={styling} ref={sectionRef}>
            <div className={classes('wrapper')}>
                <Title title={content.title} subtitle={content.subtitle} description={content.description} />
                <div className={classes('stats-grid')}>
                    {stats.map((stat, index) => (
                        <Stat key={index} attributes={stat} visible={loadStats} index={index} />
                    ))}
                </div>
            </div>
        </SectionWrap>
    )
}

export default StatsSection
