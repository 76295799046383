'use client'

import styles from './Stat.module.scss'
import classNames from 'classnames/bind'
import Media from '@/components/Layout/Media/Media'
import { useEffect, useState } from 'react'
import Icon from '../Icon/Icon'

const classes = classNames.bind(styles)

const Stat = ({ attributes, visible, index }) => {
    const { stat, text, appendText, prependText, numberOfDecimals, icon } = attributes
    const [statNumber, setStatNumber] = useState(0)

    useEffect(() => {
        if (visible) {
            setTimeout(() => {
                let newFigure = statNumber + stat / 100
                if (newFigure > stat) newFigure = stat
                setStatNumber(newFigure)
            }, 10)
        }
    }, [statNumber, visible])

    return (
        <div className={classes('wrapper', { enter: visible })} style={{ transitionDelay: `${index * 150}ms` }}>
            {stat && (
                <h2 className={classes('stat')}>
                    {prependText && <span>{prependText}</span>}
                    {statNumber.toFixed(numberOfDecimals)}
                    {appendText && <span>{appendText}</span>}
                    {icon && <Icon icon={icon} className={classes('icon')} />}
                </h2>
            )}
            {text && <p className={classes('text')}>{text}</p>}
        </div>
    )
}
export default Stat
