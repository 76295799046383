import { useReducer, createContext, useContext, useEffect } from 'react'
import questions from './config.json'

const ResidenceContext = createContext()

const ResidenceCalculatorProvider = ({ children }) => {
    const [state, dispatch] = useReducer(Reducer, {
        position: 0,
        intro: true,
        questionFlow: false,
        result: false,
        nextButtonEnabled: false,
        questions,
        currentQuestion: questions.taxYear,
        currentFlow: [questions.taxYear],
        answer: {},
    })

    useEffect(() => {
        if (state.result) {
            const calculator = document.getElementById('residence-calculator')
            if (calculator.getBoundingClientRect().top < 0) {
                calculator.scrollIntoView()
            }
        }
    }, [state.result])

    return (
        <ResidenceContext.Provider
            value={{
                state,
                dispatch,
            }}
        >
            {children}
        </ResidenceContext.Provider>
    )
}

const Reducer = (state, action) => {
    const { type, payload } = action
    switch (type) {
        case 'EXIT_INTRO':
            return { ...state, intro: false, questionFlow: true }
        case 'SET_ANSWER':
            state = { ...state, answer: { ...state.answer, [payload.key]: payload.answer } }
        case 'CONTINUE_BUTTON':
            let flow = validateFlow(state)
            if (flow.questionFlow == false) {
                return { ...state, ...flow }
            }
            // Filtering out previously answered questions after flow change.
            let newAnswerObject = {}
            flow.currentFlow.forEach((question) => (newAnswerObject[question.key] = state.answer[question.key]))
            return getSelectedQuestion({ ...state, position: state.position + 1, ...flow, answer: newAnswerObject })
        case 'SUBMIT_TIES_QUESTION':
            let currentAnswer = state.answer.ties ? state.answer.ties : {}
            return getSelectedQuestion({
                ...state,
                answer: {
                    ...state.answer,
                    ties: { ...currentAnswer, [payload.key]: payload.answer },
                },
            })
        case 'PREVIOUS_PAGE':
            if (state.position == 0) {
                return { ...state, intro: true, questionFlow: false }
            }
            return getSelectedQuestion({ ...state, position: state.position - 1 })
        case 'COMPLETE_FLOW':
            return { ...state, questionFlow: false, result: true }
        case 'RESET':
            return {
                position: 0,
                intro: true,
                questionFlow: false,
                result: false,
                nextButtonEnabled: false,
                questions,
                currentQuestion: questions.taxYear,
                currentFlow: [questions.taxYear],
                answer: {},
            }
    }
}

const useResidenceContext = () => {
    const context = useContext(ResidenceContext)
    if (context === undefined) {
        throw new Error('useResidenceContext must be used within a ResidenceContext')
    }

    return context
}

export { ResidenceCalculatorProvider, useResidenceContext }

// Helper functions

const getSelectedQuestion = (state) => {
    const newCurrentQuestion = state.currentFlow[state.position]
    if (newCurrentQuestion.type == 'Ties') {
        return {
            ...state,
            currentQuestion: newCurrentQuestion,
            nextButtonEnabled: state.answer[newCurrentQuestion.key]
                ? Object.values(state.answer[newCurrentQuestion.key]).length === newCurrentQuestion.questions.length
                : false,
        }
    }
    return {
        ...state,
        currentQuestion: newCurrentQuestion,
        nextButtonEnabled: state.answer[newCurrentQuestion.key] != null,
    }
}

const validateFlow = (state) => {
    const { answer } = state
    let currentFlow = [questions.taxYear]

    if (answer.taxYear) {
        currentFlow.push(questions.daysInUK)
    }

    if (answer.daysInUK) {
        switch (answer.daysInUK) {
            case '__45':
                return process45Days(state, currentFlow)
            case '__90':
                return process90Days(state, currentFlow)
            case '__120':
            case '__182':
                return process91PlusDays(state, currentFlow)
            default:
                return {
                    questionFlow: false,
                    result: true,
                    currentFlow,
                }
        }
    }
    return {
        currentFlow,
    }
}

const process45Days = (state, currentFlow) => {
    const { answer } = state

    currentFlow.push(questions.lastThreeYears)
    if (answer.lastThreeYears) {
        currentFlow.push(questions.overseasFullTime)

        if (answer.overseasFullTime) {
            return resultState(currentFlow)
        } else if (answer.overseasFullTime == false) {
            currentFlow.push(questions.homeInUK)

            if (answer.homeInUK) {
                return resultState(currentFlow)
            } else if (answer.homeInUK == false) {
                currentFlow.push(questions.ukFullTimeWork)

                if (answer.ukFullTimeWork) {
                    return resultState(currentFlow)
                } else if (answer.ukFullTimeWork == false) {
                    currentFlow.push(questions.ties)
                }
            }
        }
    } else if (answer.lastThreeYears == false) {
        return resultState(currentFlow)
    }
    return {
        currentFlow,
    }
}

const process90Days = (state, currentFlow) => {
    const { answer } = state

    currentFlow.push(questions.overseasFullTime)
    if (answer.overseasFullTime) {
        return resultState(currentFlow)
    } else if (answer.overseasFullTime == false) {
        currentFlow.push(questions.homeInUK)

        if (answer.homeInUK) {
            return resultState(currentFlow)
        } else if (answer.homeInUK == false) {
            currentFlow.push(questions.ukFullTimeWork)

            if (answer.ukFullTimeWork) {
                return resultState(currentFlow)
            } else if (answer.ukFullTimeWork == false) {
                currentFlow.push(questions.lastThreeYears)

                if (answer.lastThreeYears) {
                    currentFlow.push(questions.ties)
                } else if (answer.lastThreeYears == false) {
                    currentFlow.push({
                        ...questions.ties,
                        questions: questions.ties.questions.filter((question) => question.key != 'moreUKNights'),
                    })
                }
            }
        }
    }

    return {
        currentFlow,
    }
}

const process91PlusDays = (state, currentFlow) => {
    const { answer } = state

    currentFlow.push(questions.homeInUK)

    if (answer.homeInUK) {
        return resultState(currentFlow)
    } else if (answer.homeInUK == false) {
        currentFlow.push(questions.ukFullTimeWork)
        if (answer.ukFullTimeWork) {
            return resultState(currentFlow)
        } else if (answer.ukFullTimeWork == false) {
            currentFlow.push(questions.lastThreeYears)

            if (answer.lastThreeYears) {
                currentFlow.push(questions.ties)
            } else if (answer.lastThreeYears == false) {
                currentFlow.push({
                    ...questions.ties,
                    questions: questions.ties.questions.filter((question) => question.key != 'moreUKNights'),
                })
            }
        }
    }

    return {
        currentFlow,
    }
}

const resultState = (currentFlow) => ({
    questionFlow: false,
    result: true,
    currentFlow,
})
