'use client'
import React, { forwardRef, useState } from 'react'

import Input from '@/components/Elements/Input/Input'
import ChevronDown from '@/icons/chevron-down.svg'

import styles from './Dropdown.module.scss'
import classNames from 'classnames/bind'
const classes = classNames.bind(styles)

export default forwardRef((props, ref) => {
    const {
        listName,
        required,
        choices,
        className = null,
        onChange = () => {},
        callback = () => {},
        answer = null,
        id,
    } = props
    const [filteredChoices, setState] = useState(choices)

    let selectedAnswer = choices.find((listItem) => listItem.key === answer)
    const [selectedChoice, setSelected] = useState(selectedAnswer)
    const [searchBoxContent, setSearchBoxContent] = useState(selectedAnswer?.name)

    const clickDropdown = (choice) => {
        if (!choice) {
            choice = selectedChoice
        }
        setSelected(choice)
        callback(choice.key)
        onChange({ target: { value: choice.key } })
        setSearchBoxContent(choice.name)
        setState(choices)
    }

    const filterDropdown = ({ target }) => {
        setTimeout(() => {
            setState(choices.filter(({ name }) => name.toLowerCase().includes(target.value.toLowerCase())))
        }, 100)
    }

    const onSearchBlur = ({ target }) => {
        setTimeout(() => {
            try {
                let selectedRow = choices.find(({ name }) => name.toLowerCase() === target.value.toLowerCase())
                clickDropdown(selectedRow)
            } catch (e) {
                console.log(e)
            }
        }, 100)
    }

    return (
        <div className={classes('wrapper', className)}>
            <input name={listName} type={'hidden'} value={selectedChoice?.key} ref={ref} id={id} />
            <Input
                className={classes('search')}
                type="text"
                value={searchBoxContent}
                placeholder={'Select...'}
                onKeyDown={filterDropdown}
                onBlur={onSearchBlur}
                required={required}
                autoComplete={'off'}
                onChange={({ target }) => {
                    setSearchBoxContent(target.value)
                }}
            />
            <ul className={classes('choices')}>
                {filteredChoices.map((choice) => {
                    const { name, key } = choice
                    return (
                        <li key={`${listName}-${name}-${key}`} onMouseDown={() => clickDropdown(choice)}>
                            {name}
                        </li>
                    )
                })}
            </ul>
            <ChevronDown />
        </div>
    )
})
