'use client'

import styles from './BlogItemWide.module.scss'
import classNames from 'classnames/bind'
import Media from '@/components/Layout/Media/Media'
import useIntersectionObserver from '@/components/Helpers/UseIntersectionObserver/UseIntersectionObserver'

import { useRef } from 'react'

const classes = classNames.bind(styles)

const BlogItemWide = ({ ...props }) => {
    const { id, title, excerpt, image, tag, publishedAt, author, slug, index = 1 } = props

    const authorData = author.data.attributes
    const publishedDate = new Date(publishedAt)

    const month = publishedDate.toLocaleString('default', { month: 'short' })

    const formattedDate = `${month} ${publishedDate.getDate()}, ${publishedDate.getFullYear()}`

    const sectionRef = useRef(null)

    useIntersectionObserver({
        refs: [sectionRef],
        callback: ({ isIntersecting }) => {
            if (isIntersecting) {
                setTimeout(() => {
                    sectionRef.current.classList.add(classes('enter'))
                }, index * 100)
            }
        },
        options: {
            // a threshold of 0 will trigger our IntersectionObserver at the
            // base of the element – a threshold of 1 would trigger at the top.
            threshold: [0.2],
        },
    })

    return (
        <a className={classes('wrapper')} href={`/blog/${slug}`} key={id} ref={sectionRef}>
            <div className={classes('content')}>
                <div className={classes('author')}>
                    <div className={classes('avatar')}>
                        {authorData?.avatar?.data && (
                            <Media width={32} height={32} attributes={authorData?.avatar?.data?.attributes} />
                        )}
                    </div>
                    <div className={classes('author-details')}>
                        <div className={classes('name')}>{authorData?.displayName}</div>
                        <div className={classes('text-separator')} />
                        <div className={classes('publish-date')}>{formattedDate}</div>
                    </div>
                </div>
                <div className={classes('title')}>{title}</div>
                <p className={classes('excerpt')}>{excerpt}</p>
            </div>
            {image?.data && (
                <div className={classes('image')}>
                    <Media fill={true} attributes={image?.data?.attributes} />
                </div>
            )}
        </a>
    )
}
export default BlogItemWide
