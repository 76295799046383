import React from 'react'

import styles from './Input.module.scss'
import classNames from 'classnames/bind'
const classes = classNames.bind(styles)

/**
 * A simple button.
 *
 * @param {Component} children The child components to allow customisation of icon etc.
 * @param {String} type The classes for the button.
 * @param {Function} onClick The onclick function.
 * @param {String} id The ID if necessary.
 * @param {Boolean} display Whether the button is being displayed.
 * @param {Boolean} disabled Whether the button is disabled.
 */
export default React.forwardRef((props, ref) => {
    return <input {...props} className={classes('default', props?.className)} ref={ref} />
})
