'use client'

import { ResidenceCalculatorProvider, useResidenceContext } from './ResidenceCalculatorContext'
import { Fragment, useRef } from 'react'

import SectionWrap from '@/components/Layout/SectionWrap/SectionWrap'
import ThreeStepSection from '@/components/Elements/ThreeStepSection/ThreeStepSection'
import ResultsPage from './ResidenceResultsPage'
import Title from '@/components/Elements/Title/Title'
import useIntersectionObserver from '@/components/Helpers/UseIntersectionObserver/UseIntersectionObserver'

import ResetIcon from '@/icons/rotate-ccw.svg'

import styles from './ResidenceCalculator.module.scss'
import classNames from 'classnames/bind'

const classes = classNames.bind(styles)

export default function ResidenceCalculator({ attributes, index }) {
    const sectionRef = useRef(null)

    useIntersectionObserver({
        refs: [sectionRef],
        callback: ({ isIntersecting }) => {
            if (isIntersecting) {
                sectionRef.current.classList.add(classes('enter'))
            }
        },
        options: {
            // a threshold of 0 will trigger our IntersectionObserver at the
            // base of the element – a threshold of 1 would trigger at the top.
            threshold: [0.2],
        },
    })

    return (
        <SectionWrap className={classes('section-wrap')} ref={sectionRef} id="residence-calculator">
            {attributes.title && (
                <Title
                    title={attributes.title}
                    description={attributes.description}
                    index={index}
                    gradient={index == 0}
                    className={classes('section-title')}
                />
            )}
            <ResidenceCalculatorProvider>
                <CalculatorContent />
            </ResidenceCalculatorProvider>
        </SectionWrap>
    )
}

const CalculatorContent = () => {
    const { state, dispatch } = useResidenceContext()

    return (
        <div className={classes('wrapper')}>
            {state.result ? (
                <span className={classes('reset-button')} onClick={() => dispatch({ type: 'RESET' })}>
                    <ResetIcon className={classes('reset-icon')} />
                    <span>Restart test</span>
                </span>
            ) : (
                <span className={classes('title')}>
                    {!state.questionFlow ? 'UK Tax Residence Test' : `0${state.position + 1}`}
                </span>
            )}
            <IntroPage />
            <Question />
            <TiesQuestions />
            <ResultsPage />
            <NavigationButtons />
        </div>
    )
}

const IntroPage = () => {
    const { state, dispatch } = useResidenceContext()
    if (!state.intro) return null
    return (
        <ThreeStepSection
            attributes={{
                title: 'Get started below',
                description: `<p>We've made the complex Statutory Residence Test (SRT) rules easy to understand and apply.
                    Whether you've left or arrived in the UK, or are travelling in and out of the UK - we've got you covered.
                    </p><br /><p>It encompasses the essential components, including the automatic overseas test, automatic UK tests, and 
                    the sufficient ties test.</p>`,
                points: [
                    {
                        title: 'Get started',
                        text: "Click get started to kick off, you'll need around 2-5 minutes to go through the tests.",
                    },
                    {
                        title: 'Details required',
                        text: "You'll need details around your days in the UK, and ties to the UK (we'll explain what this all means!)",
                    },
                    {
                        title: 'Results on screen',
                        text: "You can get your results sent to yourself via email, but you'll also get a breakdown directly after the questionnaire.",
                    },
                ],
            }}
            onClick={() => dispatch({ type: 'EXIT_INTRO' })}
        />
    )
}

const Question = () => {
    const {
        state: { questionFlow, currentQuestion },
    } = useResidenceContext()

    if (!questionFlow) return null

    let questionComponent
    switch (currentQuestion.type) {
        case 'Single Choice':
            questionComponent = <SingleSelect questionKey={currentQuestion.key} options={currentQuestion.options} />
            break
        case 'Boolean':
            questionComponent = (
                <Boolean
                    questionKey={currentQuestion.key}
                    true_tag={currentQuestion.true_tag}
                    false_tag={currentQuestion.false_tag}
                />
            )
            break
        case 'Ties':
            return null
    }

    return (
        <QuestionWrap title={currentQuestion.title} description={currentQuestion.description}>
            {questionComponent}
        </QuestionWrap>
    )
}

const TiesQuestions = () => {
    const {
        state: { questionFlow, currentQuestion },
    } = useResidenceContext()

    if (!questionFlow || currentQuestion.type !== 'Ties') return null

    return (
        <div className={classes('multi-q-wrap')}>
            {currentQuestion.questions.map((question, index) => (
                <Fragment key={question.key}>
                    <QuestionWrap title={question.title} description={question.description} subQuestion={true}>
                        <Boolean
                            questionKey={question.key}
                            true_tag={question.true_tag}
                            false_tag={question.false_tag}
                            subQuestion={true}
                        />
                    </QuestionWrap>
                    {index + 1 !== currentQuestion.questions.length && <hr className={classes('line')} />}
                </Fragment>
            ))}
        </div>
    )
}

const QuestionWrap = ({ title, description, subQuestion, children }) => {
    const { state } = useResidenceContext()

    return (
        <div className={classes('question-wrapper', { 'sub-question': subQuestion })}>
            <div className={classes('left')}>
                <div className={classes('content')}>
                    <h2 className={classes('question-title')}>{processText(title, state.questions, state.answer)}</h2>
                    <p
                        className={classes('description')}
                        dangerouslySetInnerHTML={{ __html: processText(description, state.questions, state.answer) }}
                    />
                </div>
            </div>
            <div className={classes('right')}>{children}</div>
        </div>
    )
}

const SingleSelect = ({ questionKey, options }) => {
    const { state, dispatch } = useResidenceContext()
    return (
        <div className={classes('single-select-grid')}>
            {options.map(({ key, name }) => {
                return (
                    <div key={key}>
                        <input
                            type="radio"
                            className={classes('option-input')}
                            name={questionKey}
                            id={key}
                            value={key}
                            checked={state.answer[questionKey] == key}
                            onChange={() =>
                                dispatch({ type: 'SET_ANSWER', payload: { key: questionKey, answer: key } })
                            }
                        />
                        <label htmlFor={key} className={classes('option-label')}>
                            <div className={classes('option-symbol')}>
                                <div className={classes('dot')}></div>
                            </div>
                            <span className={classes('option-name')}>{name}</span>
                        </label>
                    </div>
                )
            })}
        </div>
    )
}

const Boolean = ({ questionKey, true_tag, false_tag, subQuestion }) => {
    const { state, dispatch } = useResidenceContext()

    const dispatchType = subQuestion ? 'SUBMIT_TIES_QUESTION' : 'SET_ANSWER'
    const answer = subQuestion ? state.answer['ties']?.[questionKey] : state.answer[questionKey]

    return (
        <div className={classes('boolean-wrapper')}>
            <div>
                <input
                    type="radio"
                    className={classes('option-input')}
                    name={questionKey}
                    id={`${questionKey}-true`}
                    value={'true'}
                    checked={answer == true}
                    onChange={() =>
                        dispatch({
                            type: dispatchType,
                            payload: { key: questionKey, answer: true },
                        })
                    }
                />
                <label htmlFor={`${questionKey}-true`} className={classes('option-label')}>
                    <div className={classes('option-symbol')}>
                        <div className={classes('dot')}></div>
                    </div>
                    <span className={classes('option-name')}>{true_tag}</span>
                </label>
            </div>
            <div>
                <input
                    type="radio"
                    className={classes('option-input')}
                    name={questionKey}
                    id={`${questionKey}-false`}
                    value={'false'}
                    checked={answer == false}
                    onChange={() =>
                        dispatch({
                            type: dispatchType,
                            payload: { key: questionKey, answer: false },
                        })
                    }
                />
                <label htmlFor={`${questionKey}-false`} className={classes('option-label')}>
                    <div className={classes('option-symbol')}>
                        <div className={classes('dot')}></div>
                    </div>
                    <span className={classes('option-name')}>{false_tag}</span>
                </label>
            </div>
        </div>
    )
}

const processText = (text, questions, answer) => {
    if (!text) return ''
    if (!text.includes('{')) return text

    let taxYear = questions.taxYear.options.find((option) => option.key == answer['taxYear'])
    text = text.replace('{tax-year}', taxYear.name)
    return text
}

const NavigationButtons = () => {
    const { state, dispatch } = useResidenceContext()

    if (!state.questionFlow) return null
    return (
        <div className={classes('navigation-buttons')}>
            <button
                className={classes('button', 'back-button')}
                onClick={(e) => {
                    e.preventDefault()
                    dispatch({ type: 'PREVIOUS_PAGE' })
                }}
            >
                Back
            </button>
            {state.currentQuestion.key == 'ties' ? (
                <button
                    className={classes('button', 'continue-button')}
                    onClick={(e) => {
                        e.preventDefault()
                        if (state.nextButtonEnabled) {
                            dispatch({ type: 'COMPLETE_FLOW' })
                        }
                    }}
                    disabled={!state.nextButtonEnabled}
                >
                    See results
                </button>
            ) : (
                <button
                    className={classes('button', 'continue-button')}
                    onClick={(e) => {
                        e.preventDefault()
                        if (state.nextButtonEnabled) {
                            dispatch({ type: 'CONTINUE_BUTTON' })
                        }
                    }}
                    disabled={!state.nextButtonEnabled}
                >
                    Continue
                </button>
            )}
        </div>
    )
}
