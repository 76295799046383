'use client'

import styles from './SmallIconText.module.scss'
import classNames from 'classnames/bind'
import Media from '@/components/Layout/Media/Media'
import { useRef, useEffect } from 'react'

const classes = classNames.bind(styles)

const SmallIconText = ({ attributes, text, visible, index }) => {
    const ref = useRef(null)

    useEffect(() => {
        if (visible) {
            setTimeout(() => {
                ref?.current?.classList?.add(classes('enter'))
            }, 50 * index)
        }
    }, [visible])

    return (
        <div className={classes('icon-text-box')} ref={ref}>
            <Media attributes={attributes} width={20} height={20} />
            <span>{text}</span>
        </div>
    )
}
export default SmallIconText
