'use client'

import styles from './Results.module.scss'
import classNames from 'classnames/bind'

import { Fragment } from 'react'
import { getValue, formatCurrencyFull } from '@/utils/index.js'

const classes = classNames.bind(styles)

const Summary = ({ results, calculatorType }) => {
    // Find which preset calculator is being used
    const selectedCalculator = outputOptions.find((item) => item.calculatorKey == calculatorType)
    console.log('selectedCalculator', calculatorType)
    return (
        <Fragment key={calculatorType}>
            <div className={classes('summary-section')}>
                {selectedCalculator.sections.map(({ key, title, outputs }) => {
                    if (key === 'bonusMonth' && !results.bonus) return null

                    return (
                        <Fragment key={title}>
                            <h4 className={classes('h4')}>{title}</h4>
                            <div className={classes('keys')}>{createLabels(results, outputs)}</div>
                        </Fragment>
                    )
                })}
            </div>
        </Fragment>
    )
}

export default Summary

const createLabels = (results, outputs) => {
    return outputs.map(({ key, label, allowZero, total }, i) => {
        const value = getValue(results, key, 0)
        if (!allowZero && value === 0) return null
        return (
            <div key={`summary-${i}`} className={classes('output-wrap')}>
                <span className={classes('summary-label')}>{label}</span>
                {total && <div className={classes('dots-separator')} />}
                <span className={classes('output', { zero: value == 0 })}>
                    {value !== 0 ? formatCurrencyFull(value) : '-'}
                </span>
            </div>
        )
    })
}

const outputOptions = [
    {
        calculatorKey: 'remittanceBasis',
        sections: [
            {
                title: 'Arising Basis',
                outputs: [
                    { label: 'Personal Allowance', key: 'arising.personalAllowance', allowZero: true },
                    { label: 'Income Tax', key: 'arising.totalIncomeTax', allowZero: true },
                ],
            },
            {
                title: 'Remittance Basis',
                outputs: [
                    {
                        label: 'Personal Allowance',
                        key: 'remittance.personalAllowance',
                        allowZero: true,
                    },
                    {
                        label: 'Income Tax',
                        key: 'remittance.totalIncomeTax',
                        allowZero: true,
                    },
                    {
                        label: 'Remittance Basis Charge',
                        key: 'remittance.remittanceBasisCharge',
                        allowZero: true,
                    },
                    {
                        label: 'Total Tax Charge',
                        key: 'remittance.totalDue',
                        allowZero: true,
                    },
                ],
            },
        ],
    },
    {
        calculatorKey: 'nic',
        sections: [
            {
                title: 'Yearly',
                outputs: [
                    { label: 'Employment income', key: 'employmentIncome', allowZero: false },
                    { label: 'Pension contributions', key: 'employmentPension', allowZero: false },
                    { label: 'Total employment income', key: 'employmentTotal', allowZero: true },
                    { label: 'Taxable employment income', key: 'taxableIncome', allowZero: true },
                    {
                        label: 'Class 1',
                        key: 'class1',
                        allowZero: false,
                    },
                    {
                        label: 'Class 2',
                        key: 'class2',
                        allowZero: false,
                    },
                    {
                        label: 'Class 4',
                        key: 'class4',
                        allowZero: false,
                    },
                    {
                        label: 'Tax due',
                        key: 'totalIncomeTax',
                        allowZero: true,
                    },
                    {
                        label: 'Take Home Pay',
                        key: 'takeHomePay',
                        allowZero: true,
                        total: true,
                    },
                ],
            },
        ],
    },
    {
        calculatorKey: 'takeHomePay',
        sections: [
            {
                title: 'Monthly',
                outputs: [
                    { label: 'Employment income', key: 'monthlyEmploymentIncome', allowZero: false },
                    { label: 'Pension contributions', key: 'monthlyEmploymentPension', allowZero: false },
                    { label: 'Total employment income', key: 'monthlyEmploymentTotal', allowZero: true },
                    { label: 'Taxable employment income', key: 'monthlyEmploymentTaxableTotal', allowZero: true },
                    {
                        label: 'Class 1',
                        key: 'monthlyClass1',
                        allowZero: false,
                    },
                    {
                        label: 'Tax due',
                        key: 'monthlyTotalIncomeTax',
                        allowZero: true,
                    },
                    {
                        label: 'Student Loan',
                        key: 'monthlyStudentLoan',
                        allowZero: false,
                    },
                    {
                        label: 'Take Home Pay',
                        key: 'monthlyTakeHomePay',
                        allowZero: true,
                        total: true,
                    },
                ],
            },
            {
                key: 'bonusMonth',
                title: 'Bonus month',
                outputs: [
                    { label: 'Employment salary', key: 'bonusMonthEmploymentSalary', allowZero: false },
                    { label: 'Bonus', key: 'bonusMonthBonus', allowZero: false },
                    { label: 'Pension contributions', key: 'bonusMonthPension', allowZero: false },
                    { label: 'Total employment income', key: 'bonusMonthEmploymentTotal', allowZero: true },
                    { label: 'Taxable employment income', key: 'bonusMonthEmploymentTaxableTotal', allowZero: true },
                    {
                        label: 'Class 1',
                        key: 'bonusMonthClass1',
                        allowZero: false,
                    },
                    {
                        label: 'Tax due',
                        key: 'bonusMonthTotalIncomeTax',
                        allowZero: true,
                    },
                    {
                        label: 'Student Loan',
                        key: 'bonusMonthStudentLoan',
                        allowZero: false,
                    },
                    {
                        label: 'Take Home Pay',
                        key: 'bonusMonthTakeHomePay',
                        allowZero: true,
                        total: true,
                    },
                ],
            },
            {
                title: 'Yearly',
                outputs: [
                    { label: 'Employment income', key: 'employmentIncome', allowZero: false },
                    { label: 'Pension contributions', key: 'employmentPension', allowZero: false },
                    { label: 'Total employment income', key: 'employmentTotal', allowZero: true },
                    { label: 'Taxable employment income', key: 'taxableIncome', allowZero: true },
                    {
                        label: 'Class 1',
                        key: 'class1',
                        allowZero: false,
                    },
                    {
                        label: 'Tax due',
                        key: 'totalIncomeTax',
                        allowZero: true,
                    },
                    {
                        label: 'Student Loan',
                        key: 'studentLoan',
                        allowZero: false,
                    },
                    {
                        label: 'Take Home Pay',
                        key: 'takeHomePay',
                        allowZero: true,
                        total: true,
                    },
                ],
            },
        ],
    },
]
