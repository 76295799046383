'use client'

import styles from './BlueIconBox.module.scss'
import classNames from 'classnames/bind'

import { useEffect, useRef, useState } from 'react'
import Icon from '../Icon/Icon'

const classes = classNames.bind(styles)

const BlueIconBox = ({ icon, text, className, visible }) => {
    if (!text) return null
    const [animateEnter, setAnimateEnter] = useState(false)
    const [animatedText, setAnimatedText] = useState('')

    useEffect(() => {
        const showText = (i = 0) => {
            setTimeout(() => {
                setAnimatedText(text.substring(0, i))
                if (i < text.length) {
                    showText(i + 1)
                }
            }, 10)
        }

        if (visible) {
            setAnimateEnter(true)
            setTimeout(() => {
                showText()
            }, 800)
        }
    }, [visible])

    return (
        <div className={classes('icon-text-box', className, { enter: animateEnter })}>
            {icon && <Icon icon={icon} className={classes('icon')} />}
            <div style={{ position: 'relative', flexGrow: 1 }}>
                <span className={classes('content')}>{animatedText}</span>
                <span className={classes('hidden-content')}>{text}</span>
            </div>
        </div>
    )
}
export default BlueIconBox
