'use client'

import { useRef, useState } from 'react'
import useIntersectionObserver from '@/components/Helpers/UseIntersectionObserver/UseIntersectionObserver'

import SectionWrap from '@/components/Layout/SectionWrap/SectionWrap'
import Title from '@/components/Elements/Title/Title'
import IconWithContent from '@/components/Elements/IconWithContent/IconWithContent'
import Markdown from 'markdown-to-jsx'

import styles from './ThreeIconContentSection.module.scss'
import classNames from 'classnames/bind'

const classes = classNames.bind(styles)

const ThreeIconContentSection = ({ attributes, index }) => {
    const { title, subtitle, description, contentTitle, contentSubtitle, contentDescription, iconContent } = attributes
    const sectionRef = useRef(null)

    useIntersectionObserver({
        refs: [sectionRef],
        callback: ({ isIntersecting }) => {
            if (isIntersecting) {
                sectionRef.current.classList.add(classes('enter'))
            }
        },
        options: {
            // a threshold of 0 will trigger our IntersectionObserver at the
            // base of the element – a threshold of 1 would trigger at the top.
            threshold: [0.2],
        },
    })

    return (
        <SectionWrap className={classes('section-wrap')} ref={sectionRef}>
            {attributes.title && (
                <Title
                    title={title}
                    description={description}
                    subtitle={subtitle}
                    index={index}
                    gradient={index == 0}
                    className={classes('section-title')}
                />
            )}
            <ThreeIconContent
                title={contentTitle}
                subtitle={contentSubtitle}
                description={contentDescription}
                iconContent={iconContent}
            />
        </SectionWrap>
    )
}

export default ThreeIconContentSection

const ThreeIconContent = ({ title, subtitle, description, iconContent }) => {
    return (
        <div className={classes('container')}>
            <span className={classes('subtitle')}>{subtitle}</span>
            <div className={classes('content-wrap')}>
                <div className={classes('content')}>
                    <h2 className={classes('title')}>{title}</h2>
                    <div className={classes('description')}>
                        <Markdown children={description || ''} />
                    </div>
                </div>
                <div className={classes('icon-wrap')}>
                    {iconContent.map((content, index) => (
                        <IconWithContent key={index} attributes={content} index={index} />
                    ))}
                </div>
            </div>
        </div>
    )
}
