import { useRef } from 'react'

import styles from './Tab.module.scss'
import classNames from 'classnames/bind'
import Button from '@/components/Links/Button/Button'
import Markdown from 'markdown-to-jsx'
import Media from '@/components/Layout/Media/Media'
import ChevronLeft from '@/icons/chevron-left.svg'
import ChevronRight from '@/icons/chevron-right.svg'

import { SwitchTransition, CSSTransition, TransitionGroup } from 'react-transition-group'

const classes = classNames.bind(styles)

const Tab = ({ attributes, numberOfTabs, setActiveTab, activeTab }) => {
    const { title, description, image, imageSide, buttons } = attributes
    const nodeRef = useRef(null)
    const contentNodeRef = useRef(null)

    return (
        <TransitionGroup className={classes('wrapper', imageSide)}>
            {image?.data && (
                <SwitchTransition mode={'out-in'}>
                    <CSSTransition
                        key={title}
                        unmountOnExit={true}
                        mountOnEnter={true}
                        timeout={600}
                        classNames={{
                            enter: classes('image-enter'),
                            enterActive: classes('image-enter-active'),
                            exit: classes('image-exit'),
                            exitActive: classes('image-exit-active'),
                        }}
                        nodeRef={nodeRef}
                    >
                        <div className={classes('image-background', 'desktop-image')} ref={nodeRef}>
                            <div className={classes('image-wrap')}>
                                <Media className={classes('image')} attributes={image?.data?.attributes} fill={true} />
                            </div>
                        </div>
                    </CSSTransition>
                </SwitchTransition>
            )}
            <SwitchTransition mode={'out-in'}>
                <CSSTransition
                    key={title}
                    unmountOnExit={true}
                    mountOnEnter={true}
                    timeout={400}
                    classNames={{
                        enter: classes('content-enter'),
                        enterActive: classes('content-enter-active'),
                        exit: classes('content-exit'),
                        exitActive: classes('content-exit-active'),
                    }}
                    nodeRef={contentNodeRef}
                >
                    <div className={classes('content')} ref={contentNodeRef}>
                        <h2 className={classes('title')}>{title}</h2>
                        <div className={classes('content-wrap')}>
                            <div className={classes('desc-wrap')}>
                                <Markdown className={classes('description')} children={description || ''} />
                                {image?.data && (
                                    <SwitchTransition mode={'out-in'}>
                                        <CSSTransition
                                            key={title}
                                            unmountOnExit={true}
                                            mountOnEnter={true}
                                            timeout={600}
                                            classNames={{
                                                enter: classes('image-enter'),
                                                enterActive: classes('image-enter-active'),
                                                exit: classes('image-exit'),
                                                exitActive: classes('image-exit-active'),
                                            }}
                                            nodeRef={nodeRef}
                                        >
                                            <div className={classes('image-background', 'phone-image')} ref={nodeRef}>
                                                <div className={classes('image-wrap')}>
                                                    <Media
                                                        className={classes('image')}
                                                        attributes={image?.data?.attributes}
                                                        fill={true}
                                                    />
                                                </div>
                                            </div>
                                        </CSSTransition>
                                    </SwitchTransition>
                                )}
                            </div>
                            {buttons && (
                                <div className={classes('buttons')}>
                                    {buttons.map((button) => (
                                        <Button {...button} key={button.id} />
                                    ))}
                                </div>
                            )}
                            {numberOfTabs > 1 && pagination(activeTab, setActiveTab, numberOfTabs)}
                        </div>
                    </div>
                </CSSTransition>
            </SwitchTransition>
        </TransitionGroup>
    )
}
export default Tab

const pagination = (activeTab, setActiveTab, numberOfTabs) => {
    return (
        <div className={classes('pagination')}>
            <div
                className={classes('svg-wrapper')}
                onClick={() => {
                    const nextTabIndex = activeTab == 0 ? 0 : activeTab - 1
                    setActiveTab(nextTabIndex)
                }}
            >
                <ChevronLeft />
            </div>
            {Array.from({ length: numberOfTabs }).map((_, index) => {
                if (
                    index === 0 ||
                    index === numberOfTabs - 1 ||
                    (index === activeTab + 2 && activeTab == 0) ||
                    (index === activeTab - 2 && activeTab == numberOfTabs - 1)
                ) {
                    return (
                        <div
                            key={index}
                            className={classes('page', { active: index === activeTab })}
                            onClick={() => setActiveTab(index)}
                        >
                            {index + 1}
                        </div>
                    )
                } else if (index === activeTab) {
                    return (
                        <div
                            key={index}
                            className={classes('page', { active: index === activeTab })}
                            onClick={() => setActiveTab(index)}
                        >
                            {index + 1}
                        </div>
                    )
                } else if (index === activeTab - 1 || index === activeTab + 1) {
                    return (
                        <div key={index} className={classes('page')} onClick={() => setActiveTab(index)}>
                            {index + 1}
                        </div>
                    )
                } else if (
                    (activeTab == numberOfTabs - 1 && index == numberOfTabs - 4) ||
                    (activeTab == 0 && index == 3) ||
                    (index == activeTab - 2 && index >= 1) ||
                    (index == activeTab + 2 && index <= numberOfTabs - 1)
                ) {
                    return (
                        <div key={index} className={classes('page')}>
                            ...
                        </div>
                    )
                }
                return null
            })}

            <div
                className={classes('svg-wrapper')}
                onClick={() => {
                    const nextTabIndex = activeTab == numberOfTabs - 1 ? numberOfTabs - 1 : activeTab + 1
                    setActiveTab(nextTabIndex)
                }}
            >
                <ChevronRight />
            </div>
        </div>
    )
}
