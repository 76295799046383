'use client'

import styles from './BlogPostsWide.module.scss'
import classNames from 'classnames/bind'
import { useState, useEffect } from 'react'

import BlogItemWide from '@/components/Elements/BlogItemWide/BlogItemWide'
import BlogItem from '@/components/Elements/BlogItem/BlogItem'

const classes = classNames.bind(styles)

const Posts = ({ data }) => {
    const [width, setWidth] = useState(2000)

    useEffect(() => {
        setWidth(window.innerWidth)
        window.addEventListener('resize', () => setWidth(window.innerWidth))
        return () => window.removeEventListener('resize', () => setWidth(window.innerWidth))
    }, [])

    return (
        <div className={classes('posts')}>
            {data &&
                data.map((item, i) => {
                    if (!item) return null
                    if (width < 768) {
                        return <BlogItem key={item.id} {...item.attributes} index={i} thirdItem={i == 2} />
                    }
                    return <BlogItemWide key={item.id} {...item.attributes} index={i} />
                })}
        </div>
    )
}
export default Posts
