'use client'

import styles from './BlogPosts.module.scss'
import classNames from 'classnames/bind'

import SectionWrap from '@/components/Layout/SectionWrap/SectionWrap'
import Title from '@/components/Elements/Title/Title'
import BlogItem from '@/components/Elements/BlogItem/BlogItem'
import Button from '@/components/Links/Button/Button'
import { useEffect, useState } from 'react'

const classes = classNames.bind(styles)

const BlogPosts = ({ attributes = {}, tag, noLimit = false }) => {
    const [page, setPage] = useState(1)
    const [blogs, setBlogs] = useState([])
    const [showMoreButton, setShowMoreButton] = useState(noLimit)

    useEffect(() => {
        const getInitialBlogs = async () => {
            setBlogs(await getBlogs(page, tag, noLimit, setShowMoreButton))
        }

        getInitialBlogs()
    }, [])

    const showMore = async () => {
        setBlogs([...blogs, ...(await getBlogs(page + 1, tag, noLimit, setShowMoreButton))])
        setPage(page + 1)
    }

    return (
        <SectionWrap styling={attributes?.styling} className={classes('content-wrap', { wide: noLimit })}>
            {attributes?.title && <Title {...attributes.title} />}
            <div className={classes('posts')}>
                {blogs.length > 0 &&
                    blogs.map((item, i) => {
                        if (!item) return null
                        return (
                            <BlogItem
                                key={item.id}
                                {...item.attributes}
                                index={1 + (i % 3)}
                                thirdItem={i == 2 && !noLimit}
                            />
                        )
                    })}
            </div>
            {showMoreButton && (
                <div className={classes('button-wrap')}>
                    <Button
                        type={'primary'}
                        text={'View more'}
                        onClick={noLimit ? showMore : null}
                        url={noLimit ? null : '/blog'}
                    />
                </div>
            )}
        </SectionWrap>
    )
}

const getBlogs = async (page, tag, noLimit, setShowMoreButton) => {
    const fetchAPI = (await import('@/utils/strapiApi.js')).default
    let tagFilter = {
        tag: {
            $eq: tag,
        },
    }

    const {
        data,
        meta: { pagination },
    } = await fetchAPI('/blogs', {
        populate: ['author.displayName', 'author.avatar', 'image'],
        filter: tag ? tagFilter : null,
        pagination: {
            page,
            pageSize: noLimit ? 9 : 3,
            withCount: true,
        },
        sort: 'createdAt:DESC',
    })
    setShowMoreButton(pagination.pageCount > page)

    return data
}

export default BlogPosts
