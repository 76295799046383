'use client'

import questions from './questions'

import { useRef, useState } from 'react'

import { PensionChargesProvider, usePensionChargesContext } from './PensionChargesContext'
import useIntersectionObserver from '@/components/Helpers/UseIntersectionObserver/UseIntersectionObserver'

import SectionWrap from '@/components/Layout/SectionWrap/SectionWrap'
import Title from '@/components/Elements/Title/Title'
import Input from '@/components/Elements/Input/Input'

import HelpCircle from '@/icons/help-circle.svg'
import ResetIcon from '@/icons/rotate-ccw.svg'
import CheckCircle from '@/icons/check-circle.svg'
import ChevronDown from '@/icons/chevron-down.svg'

import styles from './PensionChargesCalculator.module.scss'
import classNames from 'classnames/bind'
import { formatCurrencyFull, getValueNumber } from '@/utils/index'
const classes = classNames.bind(styles)

const PensionChargesCalculator = ({ attributes, index }) => {
    const sectionRef = useRef(null)

    useIntersectionObserver({
        refs: [sectionRef],
        callback: ({ isIntersecting }) => {
            if (isIntersecting) {
                sectionRef.current.classList.add(classes('enter'))
            }
        },
        options: {
            // a threshold of 0 will trigger our IntersectionObserver at the
            // base of the element – a threshold of 1 would trigger at the top.
            threshold: [0.2],
        },
    })

    return (
        <SectionWrap
            className={classes('section-wrap', { 'first-section': index == 0 })}
            ref={sectionRef}
            id="pension-charges-calculator"
        >
            {attributes.title && (
                <Title
                    title={attributes.title}
                    description={attributes.description}
                    index={index}
                    gradient={index == 0}
                    className={classes('section-title')}
                />
            )}
            <PensionChargesProvider>
                <CalculatorContent />
            </PensionChargesProvider>
        </SectionWrap>
    )
}

const CalculatorContent = () => {
    const { state, dispatch } = usePensionChargesContext()

    return (
        <div className={classes('wrapper')} id="pension-wrapper">
            {state.started ? (
                <span className={classes('reset-button')} onClick={() => dispatch({ type: 'RESET' })}>
                    <ResetIcon className={classes('reset-icon')} />
                    <span>Restart test</span>
                </span>
            ) : (
                <span className={classes('title')}>Pension Charge Calculator</span>
            )}
            <InitialQuestion />
            <YearQuestions />
            <ResultsScreen />
            <PensionStartQuestion />
        </div>
    )
}

const InitialQuestion = () => {
    const { state, dispatch } = usePensionChargesContext()

    if (state.started) return null

    return (
        <>
            <div className={classes('question-wrapper')}>
                <div className={classes('left')}>
                    <div className={classes('content')}>
                        <h2 className={classes('main-title')}>Tapered Annual Allowance Calculator</h2>
                        <p className={classes('description')}>
                            Simply select your tax year, input your income and pension contributions, and we'll estimate
                            if you owe a pension tax charge.
                            <br />
                            <br />
                            Remember, this is a general guide based on HMRC rules, not specific tax advice. Please
                            consult a tax advisor if you need personalised support.
                        </p>
                    </div>
                </div>
                <div className={classes('right')}>
                    <div className={classes('single-select-grid')}>
                        {state.allowableYears.map(({ key, name }) => {
                            return (
                                <div key={key}>
                                    <input
                                        type="radio"
                                        className={classes('option-input')}
                                        name={'__tax_year'}
                                        id={key}
                                        value={key}
                                        checked={state.mainTaxYear?.key == key}
                                        onChange={() => dispatch({ type: 'SET_TAX_YEAR', payload: key })}
                                    />
                                    <label htmlFor={key} className={classes('option-label')}>
                                        <div className={classes('option-symbol')}>
                                            <div className={classes('dot')}></div>
                                        </div>
                                        <span className={classes('option-name')}>{name}</span>
                                    </label>
                                </div>
                            )
                        })}
                    </div>
                </div>
                <div className={classes('navigation-buttons')}>
                    <button
                        className={classes('button', 'primary-button', 'cta-button')}
                        onClick={(e) => {
                            e.preventDefault()
                            if (!state.mainTaxYear) {
                                alert('Please select a tax year')
                                return
                            }
                            dispatch({ type: 'START_FLOW' })
                        }}
                    >
                        Get started
                    </button>
                </div>
            </div>
        </>
    )
}

const PensionStartQuestion = () => {
    const { state, dispatch } = usePensionChargesContext()

    if (!state.excessAllowanceFlow || state?.answer?.pensionStart) return null

    return (
        <>
            <div className={classes('question-wrapper')}>
                <div className={classes('left')}>
                    <div className={classes('content')}>
                        <h2 className={classes('main-title')}>When did you start you pension?</h2>
                        <p className={classes('description')}>
                            You can use your excess allowance from the previous three tax years if you were paying into
                            a registered pension.
                        </p>
                    </div>
                </div>
                <div className={classes('right')}>
                    <div className={classes('single-select-grid')}>
                        <div>
                            <input
                                type="radio"
                                className={classes('option-input')}
                                name={'__tax_year'}
                                id={'__before_2016'}
                                value={'__before_2016'}
                                checked={state.answer?.pensionStart == '__before_2016'}
                                onChange={() => dispatch({ type: 'SET_PENSION_START', payload: '__before_2016' })}
                            />
                            <label htmlFor={'__before_2016'} className={classes('option-label')}>
                                <div className={classes('option-symbol')}>
                                    <div className={classes('dot')}></div>
                                </div>
                                <span className={classes('option-name')}>{`> 2016`}</span>
                            </label>
                        </div>
                        {state.pensionStartYears.map(({ key, name }) => {
                            return (
                                <div key={key}>
                                    <input
                                        type="radio"
                                        className={classes('option-input')}
                                        name={'__tax_year'}
                                        id={key}
                                        value={key}
                                        checked={state.answer?.pensionStart == key}
                                        onChange={() => dispatch({ type: 'SET_PENSION_START', payload: key })}
                                    />
                                    <label htmlFor={key} className={classes('option-label')}>
                                        <div className={classes('option-symbol')}>
                                            <div className={classes('dot')}></div>
                                        </div>
                                        <span className={classes('option-name')}>{name}</span>
                                    </label>
                                </div>
                            )
                        })}
                    </div>
                </div>
                <div className={classes('navigation-buttons')}>
                    <button
                        className={classes('button', 'primary-button', 'cta-button')}
                        onClick={(e) => {
                            e.preventDefault()
                            if (!state.mainTaxYear) {
                                alert('Please select a tax year')
                                return
                            }
                            dispatch({ type: 'START_FLOW' })
                        }}
                    >
                        Get started
                    </button>
                </div>
            </div>
        </>
    )
}

const YearQuestions = () => {
    const { state, dispatch } = usePensionChargesContext()

    if (
        !state.started ||
        (state.excessAllowanceFlow && !state?.answer?.pensionStart) ||
        (state?.answer?.pensionStart && state?.tabYears?.length == 0) ||
        state.resultsScreen
    ) {
        return null
    }

    return (
        <div className={classes('split-wrap')}>
            <div className={classes('box')}>
                <HelpCircle className={classes('box-icon', 'blue')} />
                {state.excessAllowanceFlow ? (
                    <ExcessYearsContent />
                ) : (
                    <InitialYearContent name={state.mainTaxYear.name} />
                )}
            </div>
            <div>
                <Tabs />
                <div className={classes('questions', { excess: state.excessAllowanceFlow })} id="questions">
                    {/* First section */}
                    {questions.slice(0, 2).map((question) => (
                        <Question key={question.id} {...question} />
                    ))}
                    <div className={classes('content-line')}>
                        <span>Employee pension contributions</span>
                        <div className={classes('horizontal-line')} />
                    </div>
                    {questions.slice(2, 5).map((question) => (
                        <Question key={question.id} {...question} />
                    ))}
                    <div className={classes('content-line')}>
                        <span>Employer pension contributions</span>
                        <div className={classes('horizontal-line')} />
                    </div>
                    <Question key={questions[5].id} {...questions[5]} />

                    <div className={classes('button-wrap')}>
                        <span className={classes('reset-button', 'mobile')} onClick={() => dispatch({ type: 'RESET' })}>
                            <ResetIcon className={classes('reset-icon')} />
                            <span>Restart test</span>
                        </span>
                        <button
                            className={classes('button', 'primary-button', 'calculate-button')}
                            onClick={() => {
                                if (
                                    !state.excessAllowanceFlow ||
                                    state.tabYears[state.tabYears.length - 1].key == state.currentTaxYear.key
                                ) {
                                    dispatch({ type: 'CALCULATE_RESULTS' })
                                } else {
                                    let thisYearIndex = state.tabYears.findIndex(
                                        ({ key }) => key === state.currentTaxYear.key
                                    )
                                    let nextYear = state.tabYears[thisYearIndex + 1].key

                                    dispatch({
                                        type: 'SWITCH_TAX_YEAR',
                                        payload: nextYear,
                                    })
                                }
                            }}
                        >
                            {!state.excessAllowanceFlow ||
                            state.tabYears[state.tabYears.length - 1].key == state.currentTaxYear.key
                                ? 'Calculate'
                                : 'Next year'}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

const InitialYearContent = ({ name }) => {
    return (
        <>
            <span className={classes('box-title')}>Enter the following information for the {name} tax year.</span>
            <p>
                Simply select your tax year, input your income and pension contributions, and we'll estimate if you owe
                a pension tax charge.
            </p>
            <p>
                Remember, this is a general guide based on HMRC rules, not specific tax advice. Please consult a tax
                advisor if you need personalised support.
            </p>
            <p>
                Disclaimer. Your results will not be saved. This calculator is a simplified version of the Taxd Return
                flow, where we calculate all your income, deductions, reliefs, allowance, liability and so on.
            </p>
        </>
    )
}

const ExcessYearsContent = () => {
    return (
        <>
            <span className={classes('box-title')}>We need details from the previous three tax years.</span>
            <p>
                When you go over the annual pension allowance, you can mitigate against charges by looking at the 3
                previous tax years.
            </p>
            <p>
                To carry forward the pension allowance, you must have been part of a pension scheme during the years
                from which you're carrying forward.
            </p>
            <p>For personalised advice, you can book a call with a Taxd accountant on your user dashboard.</p>
        </>
    )
}

const Tabs = () => {
    const { state, dispatch } = usePensionChargesContext()

    if (!state.excessAllowanceFlow) return

    return (
        <div className={classes('tabs')}>
            {[state.mainTaxYear, ...state.tabYears].map(({ key, name }, index) => (
                <div
                    key={key}
                    className={classes('tab', { active: state.currentTaxYear.key == key })}
                    onClick={() => {
                        dispatch({ type: 'SWITCH_TAX_YEAR', payload: key })
                    }}
                >
                    <span>
                        {index == 0 ? 'Main: ' : ''}
                        {name}
                    </span>
                    <div className={classes('tab-bottom')} />
                </div>
            ))}
        </div>
    )
}

const Question = ({ id, title, description }) => {
    const { state, dispatch } = usePensionChargesContext()

    const onChange = ({ target }) => {
        dispatch({ type: 'SET_ANSWER', payload: { key: target.id, value: target.value } })
    }

    let value = state.answer?.[state.currentTaxYear.key]?.[id]

    return (
        <div className={classes('inline-question-wrapper')}>
            <div className={classes('question-content')}>
                <span className={classes('question-title')}>{title}</span>
                <p className={classes('question-description')}>{description}</p>
            </div>
            <div className={classes('input-wrapper')}>
                <span>£</span>
                <Input
                    className={classes('input')}
                    type={'number'}
                    id={id}
                    onChange={onChange}
                    value={value != null ? value : ''}
                />
            </div>
        </div>
    )
}

const ResultsScreen = () => {
    const { state, dispatch } = usePensionChargesContext()

    if (!state.resultsScreen) return null

    return (
        <div className={classes('split-wrap')}>
            <UnderAllowanceBox />
            <OverAllowanceBox />
            <NoExcessBox />
            {state.excessAllowanceFlow ? <ExcessYearsResults /> : <SingleYearResults />}

            {state.excessAllowanceFlow && (
                <span className={classes('reset-button', 'mobile')} onClick={() => dispatch({ type: 'RESET' })}>
                    <ResetIcon className={classes('reset-icon')} />
                    <span>Restart test</span>
                </span>
            )}
        </div>
    )
}

const UnderAllowanceBox = () => {
    const { state } = usePensionChargesContext()

    if (getValueNumber(state, 'results.totals.allowanceExceeded', true) > 0) return null

    return (
        <div className={classes('box', 'green')}>
            <CheckCircle className={classes('box-icon')} />
            <span className={classes('box-title')}>
                You are {state.excessAllowanceFlow ? 'now ' : ' '}under the pension allowance
            </span>
            <p>Based on what you've told us, you are under the tapered pension allowance threshold.</p>
            <p>
                So no pension charges to worry about! Although please note that this calculator aims to provide general
                guidance.
            </p>
            <p>For personalised advice, you can book a call with a Taxd accountant on your user dashboard.</p>
        </div>
    )
}

const OverAllowanceBox = () => {
    const { state, dispatch } = usePensionChargesContext()

    if (getValueNumber(state, 'results.totals.allowanceExceeded', true) == 0 || state.noExcessYears) return null

    return (
        <div className={classes('box', 'orange')}>
            <HelpCircle className={classes('box-icon')} />
            <span className={classes('box-title')}>You've exceeded your pension allowance</span>
            <p>Our calculator indicates a pension tax charge for your contributions.</p>
            <p>This can be reported on your Taxd Self Assessment.</p>
            <p>If you would like personalised advice on your situation, please book a call via the Taxd dashboard.</p>
            <button
                className={classes('button', 'primary-button', 'calculate-button')}
                onClick={() => dispatch({ type: 'START_EXCESS_FLOW' })}
            >
                Calculate
            </button>
        </div>
    )
}
const NoExcessBox = () => {
    const { state } = usePensionChargesContext()

    if (!state.noExcessYears) return null

    return (
        <div className={classes('box', 'orange')}>
            <HelpCircle className={classes('box-icon')} />
            <span className={classes('box-title')}>You have no excess allowance to use</span>
            <p>
                Unfortunately you can only use excess allowance from years where you were part of a registered pension
                scheme.
            </p>
            <p>Due to starting the pension this year, you have no allowance to bring forward.</p>
        </div>
    )
}

const SingleYearResults = () => {
    const { state } = usePensionChargesContext()

    return (
        <div className={classes('summary')}>
            <div className={classes('summary-top')}>
                <span className={classes('summary-tag')}>Calculations</span>
            </div>
            <YearResults state={state} year={state.mainTaxYear} />
        </div>
    )
}

const ExcessYearsResults = () => {
    const { state } = usePensionChargesContext()

    return (
        <div className={classes('summary')}>
            <div className={classes('summary-top')}>
                <span className={classes('summary-tag')}>Calculations</span>
            </div>
            {state.tabYears.reverse().map((year) => (
                <YearResults key={year.key} state={state} year={year} />
            ))}
            <YearResults state={state} year={state.mainTaxYear} />
        </div>
    )
}

const YearResults = ({ state, year }) => {
    const mainYear = year.key == state.mainTaxYear.key
    const excessResults = state.excessAllowanceFlow

    const [expanded, setExpanded] = useState(mainYear)

    const thresholdIncome = getValueNumber(state, `results.${year.key}.thresholdIncome`, true)
    const adjustedIncome = getValueNumber(state, `results.${year.key}.adjustedIncome`, true)
    const pensionContributions = getValueNumber(state, `answer.${year.key}.__total_pensions`, true)
    const allowance = getValueNumber(state, `results.${year.key}.pensionAllowance`, true)
    const remainingAllowance = getValueNumber(state, `results.${year.key}.remainingAllowance`, true)
    const amountExceeding = getValueNumber(state, `results.${year.key}.amountExceeding`, true)

    return (
        <div
            className={classes('summary-results', {
                expandable: !mainYear,
                'main-year-excess': mainYear && excessResults,
            })}
        >
            <div
                className={classes('summary-row', {
                    padding: excessResults,
                    expandable: !mainYear,
                    standard: mainYear && !excessResults,
                })}
                onClick={() => !mainYear && setExpanded(!expanded)}
            >
                <div className={classes('summary-year')}>{year.name}</div>
                {!mainYear && <ChevronDown className={classes('summary-expand', { expanded })} />}
            </div>
            {expanded && (
                <div className={classes('summary-wrap', { excess: excessResults })}>
                    <div className={classes('summary-row')}>
                        <div className={classes('summary-label')}>Threshold income</div>
                        <div className={classes('summary-value')}>£ {formatCurrencyFull(thresholdIncome, false)}</div>
                    </div>
                    <div className={classes('summary-row')}>
                        <div className={classes('summary-label')}>Adjusted income</div>
                        <div className={classes('summary-value')}>£ {formatCurrencyFull(adjustedIncome, false)}</div>
                    </div>
                    <div className={classes('summary-row')}>
                        <div className={classes('summary-label')}>Pension contributions</div>
                        <div className={classes('summary-value')}>
                            £ {formatCurrencyFull(pensionContributions, false)}
                        </div>
                    </div>
                    <div className={classes('summary-row')}>
                        <div className={classes('summary-label')}>Allowance</div>
                        <div className={classes('summary-value')}>£ {formatCurrencyFull(allowance, false)}</div>
                    </div>
                    {excessResults ? (
                        <div className={classes('summary-row')}>
                            <div className={classes('summary-label')}>Remaining allowance</div>
                            <div className={classes('summary-value')}>
                                £ {formatCurrencyFull(remainingAllowance, false)}
                            </div>
                        </div>
                    ) : (
                        <div className={classes('summary-row')}>
                            <div className={classes('summary-label')}>Amount exceeding</div>
                            <div className={classes('summary-value')}>
                                £ {formatCurrencyFull(amountExceeding, false)}
                            </div>
                        </div>
                    )}
                </div>
            )}
            {mainYear && excessResults && (
                <div className={classes('totals-wrap')}>
                    <div className={classes('horizontal-line')} style={{ margin: '24px 0' }} />
                    <div className={classes('summary-wrap')}>
                        <div className={classes('summary-year')}>Totals</div>
                        <div className={classes('summary-row')}>
                            <div className={classes('summary-label')}>Excess allowance from past 3 years</div>
                            <div className={classes('summary-value')}>
                                £{' '}
                                {formatCurrencyFull(
                                    getValueNumber(state, 'results.totals.excessAllowance', true),
                                    false
                                )}
                            </div>
                        </div>
                        <div className={classes('summary-row')}>
                            <div className={classes('summary-label')}>Allowance brought forward</div>
                            <div className={classes('summary-value')}>
                                £{' '}
                                {formatCurrencyFull(
                                    getValueNumber(state, 'results.totals.broughtForward', true),
                                    false
                                )}
                            </div>
                        </div>
                        <div className={classes('summary-row')}>
                            <div className={classes('summary-label')}>Amount exceeding allowance</div>
                            <div className={classes('summary-value')}>
                                £{' '}
                                {formatCurrencyFull(
                                    getValueNumber(state, 'results.totals.allowanceExceeded', true),
                                    false
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default PensionChargesCalculator
