'use client'

import styles from './CallToAction.module.scss'
import classNames from 'classnames/bind'

import Button from '@/components/Links/Button/Button'
import Markdown from 'markdown-to-jsx'
import SectionWrap from '@/components/Layout/SectionWrap/SectionWrap'
import Gradient from '@/components/Layout/Gradient/Gradient'
import useIntersectionObserver from '@/components/Helpers/UseIntersectionObserver/UseIntersectionObserver'

import { useRef } from 'react'

const classes = classNames.bind(styles)

const CallToAction = ({ attributes, blogPageCTA = false }) => {
    const { title, description, buttons } = attributes

    const sectionRef = useRef(null)
    const contentRef = useRef(null)
    const buttonsRef = useRef([])

    useIntersectionObserver({
        refs: [sectionRef],
        callback: ({ isIntersecting }) => {
            if (isIntersecting) {
                sectionRef.current.classList.add(classes('enter'))
                contentRef.current.classList.add(classes('enter'))
                buttonsRef.current.forEach((ref, i) => {
                    setTimeout(() => {
                        ref.classList.add(classes('enter'))
                    }, 250 * i)
                })
            }
        },
        options: {
            // a threshold of 0 will trigger our IntersectionObserver at the
            // base of the element – a threshold of 1 would trigger at the top.
            threshold: [0.2],
        },
    })
    return (
        <SectionWrap className={classes('content-wrap', { 'blog-cta': blogPageCTA })} ref={sectionRef}>
            <div className={classes('content')} ref={contentRef}>
                <Markdown className={classes('title')} children={title || ''} />
                {description && <Markdown className={classes('description')} children={description || ''} />}

                {buttons && (
                    <div className={classes('buttons')}>
                        {buttons.map((button, i) => (
                            <Button
                                {...button}
                                key={button.id}
                                type={'secondary'}
                                extraClasses={classes('button')}
                                ref={(el) => (buttonsRef.current[i] = el)}
                            />
                        ))}
                    </div>
                )}
            </div>
            <Gradient background={true} />
        </SectionWrap>
    )
}
export default CallToAction
