'use client'

import { useEffect, useState } from 'react'

import ComparisonsJson from './comparisons.json'

import SectionWrap from '@/components/Layout/SectionWrap/SectionWrap'
import Title from '@/components/Elements/Title/Title'
import ComparisonMobile from '@/components/Elements/ComparisonMobile/ComparisonMobile'
import ComparisonTable from '@/components/Elements/ComparisonTable/ComparisonTable'
import WindowSizeListener from '@/components/Helpers/WindowSizeListener/WindowSizeListener'

import styles from './Comparison.module.scss'
import classNames from 'classnames/bind'

const classes = classNames.bind(styles)

const Comparison = ({}) => {
    const [mobile, setMobile] = useState(false)
    const [loaded, setLoaded] = useState(false)

    useEffect(() => {
        WindowSizeListener('552px', setMobile)
        setLoaded(true)
    }, [])

    if (!loaded) return null

    return (
        <SectionWrap className={classes('wrapper')}>
            <Title
                title="What sets us apart"
                subtitle="Comparison"
                description="Check out how we compare with other popular solutions."
            />
            <div className={classes('gap')} />
            {mobile ? <ComparisonMobile data={ComparisonsJson} /> : <ComparisonTable data={ComparisonsJson} />}
        </SectionWrap>
    )
}
export default Comparison
