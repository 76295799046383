'use client'

import styles from './ContentWithSteps.module.scss'
import classNames from 'classnames/bind'
import Button from '@/components/Links/Button/Button'
import Markdown from 'markdown-to-jsx'
import SectionWrap from '@/components/Layout/SectionWrap/SectionWrap'
import Media from '@/components/Layout/Media/Media'
import Quote from '@/icons/quote-icon.svg'
import Play from '@/icons/play-button.svg'

import { useState, useRef } from 'react'

const classes = classNames.bind(styles)

const ContentWithSteps = ({ attributes }) => {
    const { firstName, lastName, quote, content, steps, image, buttons, videoLink, styling } = attributes

    const buttonsRef = useRef([])

    return (
        <SectionWrap styling={styling} className={classes('container')}>
            <div className={classes('wrapper')}>
                <Quote className={classes('quote-icon')} />
                <span className={classes('name')}>
                    {firstName} {lastName}
                </span>
                <div className={classes('row')}>
                    <div className={classes('section-wrapper')}>
                        <div className={classes('header')}>
                            <span className={classes('quote')}>"{quote}"</span>
                            <div className={classes('content')}>
                                <Markdown children={content || ''} />
                            </div>
                        </div>
                        <div className={classes('image-and-steps')}>
                            <div className={classes('steps-wrapper')}>
                                {steps && (
                                    <div className={classes('steps')}>
                                        <div className={classes('vl')} />
                                        {steps.map((step, i) => (
                                            <div className={classes('step')} key={`step-${i}`}>
                                                <div className={classes('number')}>{i + 1}</div>
                                                <div className={classes('text')}>
                                                    <span>{step}</span>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                )}
                                {buttons && (
                                    <div className={classes('buttons')}>
                                        <Button
                                            videoLink={videoLink}
                                            type={'primary'}
                                            text={'Watch Video'}
                                            extraClasses={classes('button', 'shadow')}
                                            key={'video-button'}
                                            ref={(el) => (buttonsRef.current[0] = el)}
                                        />
                                        {buttons.map((button, i) => (
                                            <Button
                                                {...button}
                                                extraClasses={classes('button')}
                                                key={button.id}
                                                ref={(el) => (buttonsRef.current[i + 1] = el)}
                                            />
                                        ))}
                                    </div>
                                )}
                            </div>

                            {image?.data && (
                                <div
                                    style={{ textAlign: 'center' }}
                                    className={classes('image-wrap')}
                                    onClick={() => buttonsRef.current[0].click()}
                                >
                                    <Play />
                                    <Media className={classes('smaller-image')} attributes={image.data.attributes} />
                                </div>
                            )}
                        </div>
                    </div>
                    {image?.data && (
                        <div
                            style={{ textAlign: 'center' }}
                            className={classes('relative-image-wrap')}
                            onClick={() => buttonsRef.current[0].click()}
                        >
                            <Play />
                            <Media className={classes('image')} attributes={image.data.attributes} />
                        </div>
                    )}
                </div>
            </div>
        </SectionWrap>
    )
}
export default ContentWithSteps
