import Icon from '../Icon/Icon'

import styles from './IconWithContent.module.scss'
import classNames from 'classnames/bind'

const classes = classNames.bind(styles)

const IconWithContent = ({ attributes, index }) => {
    const { icon, title, content } = attributes

    return (
        <div className={classes('wrap')} key={index}>
            <div className={classes('icon-wrap')}>
                <Icon icon={icon} className={classes('icon')} />
            </div>
            <div className={classes('content-wrap')}>
                <h2 className={classes('title')}>{title}</h2>
                <p className={classes('description')}>{content}</p>
            </div>
        </div>
    )
}

export default IconWithContent
