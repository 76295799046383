'use client'

import { useEffect, useState } from 'react'

const Gap = ({ attributes: { size, size_1280, size_960, size_764, size_524 } }) => {
    const [currentSize, setCurrentSize] = useState(size)

    useEffect(() => {
        const resizeCheck = () => {
            switch (true) {
                case window.innerWidth >= 1280:
                    setCurrentSize(size)
                    break
                case window.innerWidth >= 960:
                    setCurrentSize(size_1280)
                    break
                case window.innerWidth >= 764:
                    setCurrentSize(size_960)
                    break
                case window.innerWidth >= 524:
                    setCurrentSize(size_764)
                    break
                default:
                    setCurrentSize(size_524)
                    break
            }
        }

        window.addEventListener('resize', resizeCheck)
        return () => window.removeEventListener('resize', resizeCheck)
    }, [])

    return <div style={{ height: `${currentSize}px`, width: '100%' }} />
}

export default Gap
