import styles from './FaceCard.module.scss'
import classNames from 'classnames/bind'
import Media from '@/components/Layout/Media/Media'

import { useEffect, useState, useRef } from 'react'

const classes = classNames.bind(styles)

const FaceCard = ({ image, name, role, bio, visible, timing }) => {
    const [animatedBio, setAnimatedBio] = useState('')
    const ref = useRef(null)

    useEffect(() => {
        const showBio = (i = 0) => {
            setTimeout(() => {
                setAnimatedBio(bio.substring(0, i))
                if (i < bio.length) {
                    showBio(i + 1)
                }
            }, 10)
        }

        if (visible) {
            setTimeout(() => {
                ref.current.classList.add(classes('enter'))
                setTimeout(showBio(), 500)
            }, timing * 300)
        }
    }, [visible])

    return (
        <div className={classes('wrapper')} ref={ref}>
            {image?.data && <Media className={classes('image')} attributes={image?.data?.attributes} />}
            <div className={classes('content-wrapper')}>
                <div className={classes('name-wrapper')}>
                    <h2 className={classes('name')}>{name}</h2>
                    <span className={classes('role')}>{role}</span>
                </div>
                <div style={{ position: 'relative' }}>
                    <p className={classes('bio')}>{animatedBio}</p>
                    <p className={classes('hidden-bio')}>{bio}</p>
                </div>
            </div>
        </div>
    )
}
export default FaceCard
