'use client'

import ChevronRight from '@/icons/chevron-right.svg'

import styles from './TabSection.module.scss'
import classNames from 'classnames/bind'
import Tab from '@/components/Elements/Tab/Tab'
import SectionWrap from '@/components/Layout/SectionWrap/SectionWrap'
import useIntersectionObserver from '@/components/Helpers/UseIntersectionObserver/UseIntersectionObserver'

import { Fragment, useState, useRef, useEffect } from 'react'

import TabSeparator from '@/icons/slash-divider.svg'

const classes = classNames.bind(styles)

const TabSection = ({ attributes }) => {
    const { tab, type, styling } = attributes

    const [activeTab, setActiveTab] = useState(0)
    const [chevron, showChevron] = useState()

    const sectionRef = useRef(null)
    const tabRowParentRef = useRef(null)
    const tabRowRef = useRef(null)
    const tabRef = useRef(null)
    const tabsRef = useRef([])
    const tabSeparatorsRef = useRef([])

    const [loaded, setLoaded] = useState(false)

    useIntersectionObserver({
        refs: [sectionRef],
        callback: ({ isIntersecting }) => {
            if (isIntersecting) {
                tabRef.current.classList.add(classes('enter'))
                tabsRef.current.forEach((ref, i) => {
                    setTimeout(() => {
                        ref.classList.remove(classes('hidden'))
                    }, 250 * i)
                })
                tabSeparatorsRef.current.forEach((ref, i) => {
                    setTimeout(() => {
                        ref.classList.add(classes('enter'))
                    }, 350 * i)
                })
                setTimeout(() => {
                    setLoaded(true)
                }, 250 * tab.length)
            }
        },
        options: {
            // a threshold of 0 will trigger our IntersectionObserver at the
            // base of the element – a threshold of 1 would trigger at the top.
            threshold: [0.2],
        },
    })

    let resizeObserver = null

    useEffect(() => {
        const checkTabsWidth = () => {
            const parentWidth = tabRowParentRef.current.offsetWidth
            const tabRowWidth = tabRowRef.current.offsetWidth

            showChevron(tabRowWidth > parentWidth - 64)
        }

        resizeObserver = new ResizeObserver(checkTabsWidth).observe(tabRowParentRef.current)

        return () => {
            try {
                resizeObserver.disconnect()
            } catch (e) {}
        }
    }, [])

    return (
        <SectionWrap
            styling={styling}
            ref={sectionRef}
            className={classes('container', { blue: styling.blueBackground })}
        >
            <div className={classes('tabs-wrapper')}>
                <div className={classes('tab-row-wrap')} ref={tabRowParentRef}>
                    <div className={classes('tabs', type)} ref={tabRowRef}>
                        {tab &&
                            tab.map((child, index) => {
                                return (
                                    <Fragment key={index}>
                                        <div
                                            className={classes('tab', { active: index === activeTab, hidden: !loaded })}
                                            onClick={() => {
                                                setActiveTab(index)
                                            }}
                                            ref={(el) => (tabsRef.current[index] = el)}
                                        >
                                            <span className={classes('name')}>{child.tabName}</span>
                                            <span className={classes('subtitle')}>{child.subtitle}</span>
                                        </div>
                                        {type === 'auto-scroll' && index !== tab.length - 1 && (
                                            <div
                                                className={classes('separator')}
                                                ref={(el) => (tabSeparatorsRef.current[index] = el)}
                                            >
                                                <TabSeparator />
                                            </div>
                                        )}
                                    </Fragment>
                                )
                            })}
                    </div>
                </div>
                {chevron && (
                    <div className={classes('chevron-wrap', { blue: styling.blueBackground })}>
                        <div className={classes('chevron')}>
                            <ChevronRight
                                onClick={() => {
                                    const nextTabIndex = activeTab == tab.length - 1 ? 0 : activeTab + 1
                                    setActiveTab(nextTabIndex)
                                    tabsRef.current[nextTabIndex].scrollIntoView({
                                        block: 'nearest',
                                        inline: 'end',
                                        behavior: 'smooth',
                                    })
                                }}
                            />
                        </div>
                    </div>
                )}
            </div>
            <div className={classes('wrapper')}>
                <div className={classes('content-wrapper')}>
                    <div className={classes('content')} ref={tabRef}>
                        <Tab
                            key={activeTab.tabName}
                            attributes={tab[activeTab]}
                            numberOfTabs={tab.length}
                            setActiveTab={setActiveTab}
                            activeTab={activeTab}
                        />
                    </div>
                </div>
            </div>
        </SectionWrap>
    )
}

export default TabSection
