import { useReducer, createContext, useContext, useEffect } from 'react'
import { getValueNumber, getValue, addToObject } from '@/utils/index'

import Config from './config.json'

const PensionChargesContext = createContext()

const PensionChargesProvider = ({ children }) => {
    const [state, dispatch] = useReducer(Reducer, {
        started: false,
        resultsScreen: false,
        excessAllowanceFlow: false,
        noExcessYears: false,

        mainTaxYear: null,
        currentTaxYear: null,
        allowableYears: Config.filter(({ selectable }) => selectable),
        pensionStartYears: null,
        tabYears: null,
        answer: {},
        results: {},
    })

    return (
        <PensionChargesContext.Provider
            value={{
                state,
                dispatch,
            }}
        >
            {children}
        </PensionChargesContext.Provider>
    )
}

const Reducer = (state, action) => {
    const { type, payload } = action
    switch (type) {
        case 'START_FLOW':
            return {
                ...state,
                started: true,
            }
        case 'SET_TAX_YEAR':
            const yearIndex = Config.findIndex(({ key }) => key === payload)
            const newYear = Config[yearIndex]

            return {
                ...state,
                mainTaxYear: newYear,
                currentTaxYear: newYear,
                pensionStartYears: Config.slice(0 - yearIndex).reverse(),
            }
        case 'SWITCH_TAX_YEAR':
            document.getElementById('pension-wrapper').scrollIntoView({ behavior: 'smooth', block: 'start' })
            return {
                ...state,
                currentTaxYear: Config.find(({ key }) => key === payload),
            }
        case 'SET_PENSION_START':
            const currentYearIndex = Config.findIndex(({ key }) => key === state.currentTaxYear.key)
            const startYearIndex =
                payload === '__before_2016' ? Config.length - 1 : Config.findIndex(({ key }) => key === payload)

            const tabYears = Config.slice(currentYearIndex + 1, Math.min(currentYearIndex + 4, startYearIndex + 1))

            let pensionStartStateObject = {
                ...state,
                tabYears,
                answer: {
                    ...state.answer,
                    pensionStart: payload,
                },
                currentTaxYear: tabYears?.[0],
            }

            if (tabYears.length != 0) return pensionStartStateObject

            document.getElementById('pension-wrapper').scrollIntoView({ behavior: 'smooth' })

            pensionStartStateObject = {
                ...pensionStartStateObject,
                resultsScreen: true,
                noExcessYears: true,
                excessAllowanceFlow: false,
                currentTaxYear: state.mainTaxYear,
            }

            return {
                ...pensionStartStateObject,
                ...calculateResults(pensionStartStateObject),
            }

        case 'SET_ANSWER':
            return {
                ...state,
                answer: {
                    ...state.answer,
                    [state.currentTaxYear.key]: {
                        ...state?.answer?.[state.currentTaxYear.key],
                        [payload.key]: parseFloat(payload.value),
                    },
                },
            }
        case 'RESET':
            return {
                ...state,
                started: false,
                resultsScreen: false,
                excessAllowanceFlow: false,
                noExcessYears: false,

                mainTaxYear: null,
                currentTaxYear: null,
                pensionStartYears: null,
                tabYears: null,
                answer: {},
                results: {},
            }
        case 'CALCULATE_RESULTS':
            document.getElementById('pension-wrapper').scrollIntoView({ behavior: 'smooth' })

            return {
                ...state,
                resultsScreen: true,
                ...calculateResults(state),
            }
        case 'START_EXCESS_FLOW':
            return {
                ...state,
                resultsScreen: false,
                excessAllowanceFlow: true,
            }
        default:
            return state
    }
}

const usePensionChargesContext = () => {
    const context = useContext(PensionChargesContext)
    if (context === undefined) {
        throw new Error('usePensionChargesContext must be used within a PensionChargesContext')
    }

    return context
}

export { PensionChargesProvider, usePensionChargesContext }

const calculateResults = (state) => {
    let results = {
        [state.mainTaxYear.key]: calculateAllowance(
            state.mainTaxYear,
            getValue(state, `answer.${state.mainTaxYear.key}`, {})
        ),
    }

    if (!state.excessAllowanceFlow) {
        results.totals = {
            allowanceExceeded: getValueNumber(results, `${state.mainTaxYear.key}.amountExceeding`, true),
        }
    } else {
        state.tabYears.forEach((year) => {
            addToObject(results, null, year.key, calculateAllowance(year, getValue(state, `answer.${year.key}`, {})))
        })

        const excessAllowance = state.tabYears.reduce((pv, cv) => {
            return pv + getValueNumber(results, `${cv.key}.remainingAllowance`, true)
        }, 0)
        const broughtForward = Math.min(excessAllowance, results[state.mainTaxYear.key].amountExceeding)
        const allowanceExceeded = results[state.mainTaxYear.key].amountExceeding - broughtForward

        results.totals = {
            excessAllowance: excessAllowance,
            broughtForward: broughtForward,
            allowanceExceeded: allowanceExceeded,
        }
    }

    return {
        results,
    }
}

const calculateAllowance = (currentTaxYear, answers) => {
    const { allowance, thresholdIncomeLimit, adjustedIncomeLimit, minimumTaperedAllowance } = currentTaxYear

    const adjustedIncome =
        getValueNumber(answers, '__total_income', true) -
        getValueNumber(answers, '__allowable_reliefs', true) +
        getValueNumber(answers, '__net_pay_contributions', true) +
        getValueNumber(answers, '__employer_pension_money_purchase', true) +
        getValueNumber(answers, '__total_db', true) -
        getValueNumber(answers, '__employee_db', true) -
        getValueNumber(answers, '__taxable_death', true)

    const thresholdIncome =
        getValueNumber(answers, '__total_income', true) -
        getValueNumber(answers, '__allowable_reliefs', true) -
        getValueNumber(answers, '__relief_at_source', true) +
        getValueNumber(answers, '__employment_income_given_up', true) -
        getValueNumber(answers, '__taxable_death', true)

    const pensionContributions =
        getValueNumber(answers, '__net_pay_contributions', true) +
        getValueNumber(answers, '__relief_at_source', true) +
        getValueNumber(answers, '__employment_income_given_up', true) +
        getValueNumber(answers, '__employer_pension_money_purchase', true)

    let adjustedAllowance = allowance

    if (thresholdIncome > thresholdIncomeLimit && adjustedIncome > adjustedIncomeLimit) {
        adjustedAllowance = Math.max(allowance - (adjustedIncome - adjustedIncomeLimit) / 2, minimumTaperedAllowance)
    }

    return {
        adjustedIncome,
        thresholdIncome,
        pensionAllowance: adjustedAllowance,
        amountExceeding: Math.max(pensionContributions - adjustedAllowance, 0),
        remainingAllowance: Math.max(adjustedAllowance - pensionContributions, 0),
    }
}
