const { useRef, forwardRef } = require('react')
import useIntersectionObserver from '@/components/Helpers/UseIntersectionObserver/UseIntersectionObserver'

import HMRCIcon from '@/icons/hmrc.svg'
import Phone from '@/icons/phone.svg'
import PrimaryLogo from '@/images/logo/logo-primary.svg'

import Cross from '@/icons/grey-cross.svg'
import CheckHollow from '@/icons/secondary-tick.svg'
import CheckDark from '@/icons/blue-tick.svg'

import styles from './ComparisonTable.module.scss'
import classNames from 'classnames/bind'

const classes = classNames.bind(styles)

const ComparisonTable = ({ data }) => {
    const sectionRef = useRef(null)
    const featuresRef = useRef([])

    useIntersectionObserver({
        refs: [sectionRef],
        callback: ({ isIntersecting }) => {
            if (isIntersecting) {
                setTimeout(() => {
                    sectionRef.current.classList.add(classes('enter'))

                    featuresRef.current.forEach((ref, i) => {
                        setTimeout(() => {
                            ref.classList.add(classes('enter'))
                        }, 100 * i)
                    })
                }, 100)
            }
        },
        options: {
            // a threshold of 0 will trigger our IntersectionObserver at the
            // base of the element – a threshold of 1 would trigger at the top.
            threshold: [0.2],
        },
    })

    return (
        <div className={classes('wrapper')} ref={sectionRef}>
            <div className={classes('spacer')}></div>
            <table className={classes('comparison-table')}>
                <thead>
                    <tr>
                        <th></th>
                        <th>
                            <div className={classes('header')}>
                                <HMRCIcon className={classes('header-icon')} />
                                <span>HMRC</span>
                            </div>
                        </th>
                        <th>
                            <div className={classes('taxd-column')}>
                                <div className={classes('header')}>
                                    <PrimaryLogo className={classes('header-logo')} />
                                </div>
                            </div>
                        </th>
                        <th>
                            <div className={classes('header')}>
                                <Phone className={classes('header-icon', 'accountant-icon')} />
                                <span>Accountant</span>
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((item, index) => (
                        <FeatureRow key={index} {...item} ref={(el) => (featuresRef.current[index] = el)} />
                    ))}
                </tbody>
            </table>
        </div>
    )
}

const FeatureRow = forwardRef(({ title, description, values }, ref) => {
    return (
        <tr className={classes('comparison-row')} ref={ref}>
            <td>
                <div className={classes('feature')}>
                    <span>{title}</span>
                    <p>{description}</p>
                </div>
            </td>
            <td>{values.hmrc ? <CheckHollow /> : <Cross />}</td>
            <td className={classes('taxd-column')}>
                <CheckDark />
            </td>
            <td>{values.accountant ? <CheckHollow /> : <Cross />}</td>
        </tr>
    )
})

export default ComparisonTable
