import { useState, useEffect } from 'react'

import WindowSizeListener from '@/components/Helpers/WindowSizeListener/WindowSizeListener'

import styles from './ResidenceTable.module.scss'
import classNames from 'classnames/bind'

const classes = classNames.bind(styles)

export default function ResidenceTable({ threeYears, ties, days }) {
    const [loaded, setLoaded] = useState(false)
    const [residencyLabels, setResidencyLabels] = useState(['Non-resident', 'Resident'])
    const selectedConfig = getConfig(threeYears)

    const daysPosition = dayKeys.findIndex((key) => key === days)
    const tiesPosition = threeYears ? ties : Math.max(0, ties - 1)

    useEffect(() => {
        WindowSizeListener('650px', (v) => {
            setResidencyLabels(v ? ['NR', 'R'] : ['Non-R', 'R'])
        })
        WindowSizeListener('1079px', (v) => {
            setResidencyLabels(v ? ['Non-R', 'R'] : ['Non-resident', 'Resident'])
        })
        setLoaded(true)
    }, [])

    if (!loaded) return null

    return (
        <div className={classes('residence-table')}>
            <div className={classes('corner')}>Number of days spent in the UK in a tax year</div>
            <div className={classes('title', { residentTitle: threeYears })}>
                {threeYears
                    ? 'When resident in any of the 3 prior tax years'
                    : 'When non-resident throughout the 3 prior tax years'}
            </div>
            <div className={classes('mobile-ties-row', { residentTitle: threeYears })}>Number of UK ties</div>
            {dayLabels.map((label, index) => (
                <div
                    className={classes('days-label', {
                        last: index + 1 == dayLabels.length,
                    })}
                    key={`days-${index}`}
                >
                    <span className={classes('days-text')}>{label}</span>
                    <span className={classes('days-text-short')}>{mobileDayLabels[index]}</span>
                </div>
            ))}
            {selectedConfig.map(({ title, numberTitle }, index) => (
                <div
                    className={classes('ties-title', { last: index + 1 == selectedConfig.length })}
                    key={`ties-${index}`}
                >
                    <span className={classes('ties-text')}>{title}</span>
                    <span className={classes('ties-text-number')}>{numberTitle}</span>
                </div>
            ))}
            {selectedConfig.map(({ values }, tiesIndex) =>
                values.map((resident, dayIndex) => (
                    <div
                        className={classes('resident-box', `box-${dayIndex.toString()}`, {
                            [`position`]: tiesIndex == tiesPosition && dayIndex == daysPosition,
                            resident,
                        })}
                        style={{ gridColumn: tiesIndex + 2 }}
                        key={`ties-${tiesIndex}-days-${dayIndex}`}
                    >
                        <div className={classes('resident-status', { resident })}>{residencyLabels[+resident]}</div>
                    </div>
                ))
            )}
            <div className={classes('mobile-legend', { residentTitle: threeYears })}>
                <div className={classes('legend-section')}>
                    <div className={classes('resident-status')}>{residencyLabels[0]}</div>
                    <span>=</span>
                    <span>Non-resident</span>
                </div>
                <div className={classes('vertical-line')} />
                <div className={classes('legend-section')}>
                    <div className={classes('resident-status', 'resident')}>{residencyLabels[1]}</div>
                    <span>=</span>
                    <span>Resident</span>
                </div>
            </div>
        </div>
    )
}

const dayKeys = ['__15', '__45', '__90', '__120', '__182', '__183']

const dayLabels = ['Under 16 days', '16 to 45 days', '46 to 90 days', '91 to 120 days', '121 to 182 days', '183+ days']

const mobileDayLabels = ['>16', '16-45', '46-90', '91-120', '121-182', '183+']

const getConfig = (threeYears) =>
    threeYears
        ? [
              {
                  title: 'No UK ties',
                  numberTitle: 0,
                  values: [false, false, false, false, false, true],
              },
              {
                  title: '1 UK ties',
                  numberTitle: 1,
                  values: [false, false, false, false, true, true],
              },
              {
                  title: '2 UK ties',
                  numberTitle: 2,
                  values: [false, false, false, true, true, true],
              },
              {
                  title: '3 UK ties',
                  numberTitle: 3,
                  values: [false, false, true, true, true, true],
              },
              {
                  title: '4+ UK ties',
                  numberTitle: '4+',
                  values: [false, true, true, true, true, true],
              },
          ]
        : [
              {
                  title: '1 or No UK ties',
                  numberTitle: '0-1',
                  values: [false, false, false, false, false, true],
              },
              {
                  title: '2 UK ties',
                  numberTitle: 2,
                  values: [false, false, false, false, true, true],
              },
              {
                  title: '3 UK ties',
                  numberTitle: 3,
                  values: [false, false, false, true, true, true],
              },
              {
                  title: '4+ UK ties',
                  numberTitle: '4+',
                  values: [false, false, true, true, true, true],
              },
          ]
