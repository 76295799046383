'use client'

import styles from './Results.module.scss'
import classNames from 'classnames/bind'

import { Fragment } from 'react'
import { getValue, formatCurrencyFull } from '@/utils/index.js'

const classes = classNames.bind(styles)

const Breakdown = ({ results, summaryConfig }) => {
    // Create the summary section
    // Labels are created for each section relating to the summary config

    let overview = 'Tax Breakdown'
    if (summaryConfig.keys.includes('cis')) {
        let string = 'Tax Due'
        let tax = getValue(results, 'totalDue', 0)

        if (results.totalDue < 0) {
            string = 'Tax Rebate'
            tax = tax * -1
        }

        overview = `${string}: ${formatCurrencyFull(tax)}`
    }
    return (
        <Fragment key={summaryConfig?.title}>
            <div className={classes('summary-section')}>
                <h4 className={classes('h4')}>{overview}</h4>
                {createLabels(results, summaryConfig, 'income', 'Income received (before tax)')}
                {createLabels(results, summaryConfig, 'allowance', 'Minus allowances')}
                {createLabels(results, summaryConfig, 'taxable', 'Taxable income')}
                {createLabels(results, summaryConfig, 'totals', 'Tax and NIC')}
            </div>
        </Fragment>
    )
}

export default Breakdown

const createLabels = (results, summaryConfig, categoryKey, title) => {
    /**
     * Get all the outputs that are enabled for this section
     * and then filter them by the category key
     * then map them to the output component
     */
    let enabledSections = outputOptions
        .filter(({ sectionKey }) => summaryConfig.keys.includes(sectionKey) || sectionKey == 'general')
        .map(({ outputs }) => outputs)
        .flat()

    let typeMatches = enabledSections.filter(({ type }) => type === categoryKey)

    if (typeMatches.filter((type) => getValue(results, type.key) > 0).length == 0) return null
    return (
        <div className={classes('keys')}>
            <span>{title}</span>
            {typeMatches.map(({ key, label, allowZero, total }, i) => {
                let string = 'Tax Due'
                let value = getValue(results, key, 0)

                if (key == 'totalDue') {
                    if (results.totalDue < 0) {
                        string = 'Tax Rebate'
                        value = value * -1
                    }
                }

                if (!allowZero && value === 0) return null
                return (
                    <div key={`summary-${i}`} className={classes('output-wrap')}>
                        <span className={classes('summary-label', { 'total-section': categoryKey == 'totals' })}>
                            {key == 'totalDue' ? string : label}
                        </span>
                        {total && <div className={classes('dots-separator')} />}
                        <span className={classes('output', { zero: value == 0 })}>
                            {value !== 0 ? formatCurrencyFull(value) : '-'}
                        </span>
                    </div>
                )
            })}
        </div>
    )
}

// This is the config for the summary section
const outputOptions = [
    {
        sectionKey: 'employment',
        outputs: [
            { label: 'Employment income', key: 'employmentIncome', type: 'income', allowZero: false },
            { label: 'Allowable expenses', key: 'employmentExpenses', type: 'income', allowZero: false },
            { label: 'Taxable benefits', key: 'employmentBenefits', type: 'income', allowZero: false },
            { label: 'Pension contributions', key: 'employmentPension', type: 'income', allowZero: false },
            { label: 'Taxable employment income', key: 'employmentTotal', type: 'income', allowZero: true },
            { label: 'Tax on employment', key: 'employmentTax', type: 'totals', allowZero: true },
        ],
    },
    {
        sectionKey: 'selfEmployment',
        outputs: [
            { label: 'Self-employment turnover', key: 'selfIncome', type: 'income', allowZero: true },
            { label: 'Self-employment expenses', key: 'selfExpenses', type: 'income', allowZero: false },
            { label: 'Trading allowance', key: 'tradingAllowance', type: 'income', allowZero: false },
            { label: 'Self employment profit', key: 'selfProfit', type: 'income', allowZero: true },
            { label: 'Tax on self-employment', key: 'selfTax', type: 'totals', allowZero: true },
        ],
    },
    {
        sectionKey: 'cis',
        outputs: [
            { label: 'Self employment profit', key: 'selfProfit', type: 'income', allowZero: true },
            { label: 'Taxable income', key: 'taxableIncome', type: 'taxable', allowZero: true, total: true },

            {
                label: 'Total income tax and NIC due',
                key: 'totalIncomeTaxAndNIC',
                type: 'totals',
                allowZero: true,
                total: false,
            },
            { label: 'CIS tax paid', key: 'cisPaid', type: 'totals', allowZero: true, total: false },
        ],
    },
    {
        sectionKey: 'rental',
        outputs: [
            { label: 'Your share of rental income', key: 'rentalIncome', type: 'income', allowZero: false },
            { label: 'Your share of expenses', key: 'rentalExpenses', type: 'income', allowZero: false },
            { label: 'Your share of Mortgage interest', key: 'mortgageRelief', type: 'allowance', allowZero: false },
            { label: 'Taxable rental income', key: 'rentalProfit', type: 'income', allowZero: true },
            { label: 'Tax on rental', key: 'rentalTax', type: 'totals', allowZero: true },
        ],
    },
    {
        sectionKey: 'marriageAllowance',
        outputs: [{ label: 'Marriage allowance', key: 'marriageAllowance', type: 'allowance', allowZero: true }],
    },
    {
        sectionKey: 'cgt',
        outputs: [
            { label: 'Capital Gains Total', key: 'cgtTotal', type: 'income', allowZero: true },
            { label: 'Capital Gains Allowance Used', key: 'cgtAllowanceUsed', type: 'allowance', allowZero: true },
            { label: 'Capital Gains Tax', key: 'capitalGainsTax', type: 'totals', allowZero: true },
        ],
    },
    {
        sectionKey: 'nic',
        outputs: [{ label: 'NIC due', key: 'NIC', type: 'totals', allowZero: true }],
    },
    {
        sectionKey: 'taxInfo',
        outputs: [
            { label: 'Taxable income', key: 'taxableIncome', type: 'taxable', allowZero: true, total: true },
            { label: 'Adjusted Income', key: 'adjustedIncome', type: 'income', allowZero: true, total: true },
            {
                label: 'Total income tax due',
                key: 'totalIncomeTax',
                type: 'totals',
                allowZero: true,
                total: false,
            },
        ],
    },
    {
        sectionKey: 'general',
        outputs: [
            { label: 'Personal Allowance', key: 'personalAllowance', type: 'allowance', allowZero: true },
            { label: 'Payments on account', key: 'paymentsOnAccount', type: 'totals', allowZero: false },
            { label: 'Tax due', key: 'totalDue', type: 'totals', allowZero: false, total: true },
        ],
    },
]
