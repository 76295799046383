'use client'

import styles from './BlogItem.module.scss'
import classNames from 'classnames/bind'
import Media from '@/components/Layout/Media/Media'
import useIntersectionObserver from '@/components/Helpers/UseIntersectionObserver/UseIntersectionObserver'

import { useRef } from 'react'

const classes = classNames.bind(styles)

const BlogItem = ({ ...props }) => {
    const { id, title, excerpt, image, tag, publishedAt, author, slug, thirdItem, index = 1 } = props

    const authorData = author.data.attributes
    const publishedDate = new Date(publishedAt)

    const month = publishedDate.toLocaleString('default', { month: 'short' })

    const formattedDate = `${month} ${publishedDate.getDate()}, ${publishedDate.getFullYear()}`

    const sectionRef = useRef(null)

    useIntersectionObserver({
        refs: [sectionRef],
        callback: ({ isIntersecting }) => {
            if (isIntersecting) {
                setTimeout(() => {
                    sectionRef.current.classList.add(classes('enter'))
                }, index * 100)
            }
        },
        options: {
            // a threshold of 0 will trigger our IntersectionObserver at the
            // base of the element – a threshold of 1 would trigger at the top.
            threshold: [0.2],
        },
    })

    return (
        <a className={classes('wrapper', { 'third-item': thirdItem })} href={`/blog/${slug}`} key={id} ref={sectionRef}>
            <div className={classes('image')}>
                {image?.data && <Media fill={true} attributes={image?.data?.attributes} />}
            </div>
            <div className={classes('content')}>
                <div className={classes('tag')}>{tag}</div>
                <div className={classes('title')}>
                    <h3 className={classes('title')}>{title}</h3>
                </div>
                <p className={classes('excerpt')}>{excerpt}</p>
                <div className={classes('author')}>
                    <div className={classes('avatar')}>
                        {authorData?.avatar?.data && (
                            <Media width={48} height={48} attributes={authorData?.avatar?.data?.attributes} />
                        )}
                    </div>
                    <div className={classes('author-details')}>
                        <div className={classes('name')}>{authorData?.displayName}</div>
                        <div className={classes('publish-date')}>{formattedDate}</div>
                    </div>
                </div>
            </div>
        </a>
    )
}
export default BlogItem
