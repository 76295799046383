import ResultOptions from './results.json'
import questions from './config.json'

import styles from './ResidenceCalculator.module.scss'
import classNames from 'classnames/bind'
const classes = classNames.bind(styles)

const getResult = ({ answer }, tieCount) => {
    const days = answer.daysInUK
    const lastThreeYears = answer.lastThreeYears

    if (days === '__15') {
        return ResultOptions['under16Days']
    }

    if (days === '__183') {
        return ResultOptions['over182Days']
    }

    if (!lastThreeYears && days === '__45') {
        return ResultOptions['45DaysNoLast3Years']
    }

    if (answer.overseasFullTime) {
        return ResultOptions['overseasFullTime']
    }

    if (answer.homeInUK) {
        return ResultOptions['UKHome']
    }

    if (answer.ukFullTimeWork) {
        return ResultOptions['fullTimeUK']
    }

    let threshold
    switch (days) {
        case '__45':
            threshold = 3
            break
        case '__90':
            threshold = lastThreeYears ? 2 : 3
            break
        case '__120':
            threshold = lastThreeYears ? 1 : 2
            break
        case '__182':
            threshold = lastThreeYears ? 0 : 1
            break
    }

    return { ...ResultOptions['ties'], count: tieCount, days, resident: tieCount > threshold }
}

const countTies = (answer) => {
    if (answer.ties == null) return 0
    return Object.values(answer.ties).filter((tie) => tie).length
}

const dayKeys = ['__15', '__45', '__90', '__120', '__182', '__183']
const dayLabels = [
    'less than 16',
    'between 16-45',
    'between 46-90',
    'between 91-120',
    'between 121-182',
    'more than 183',
]

const getTiesText = ({ answer }, resident) => {
    const tiesCount = countTies(answer)
    const days = answer.daysInUK

    const daysIndex = dayKeys.indexOf(days)

    let closeSituations = []

    // Check days above
    const daysAboveResult = getResult({ answer: { ...answer, daysInUK: dayKeys[daysIndex - 1] } }, tiesCount)
    if (daysAboveResult.resident != resident) {
        closeSituations.push(`spend ${dayLabels[daysIndex - 1]} days in the UK`)
    }
    // Check days below
    const daysBelowResult = getResult({ answer: { ...answer, daysInUK: dayKeys[daysIndex + 1] } }, tiesCount)
    if (daysBelowResult.resident != resident) {
        closeSituations.push(`spend ${dayLabels[daysIndex + 1]} days in the UK`)
    }
    // Check ties above
    const tiesAboveResult = getResult({ answer }, tiesCount + 1)
    if (tiesAboveResult.resident != resident) {
        closeSituations.push(`gain another tie to the UK`)
    }
    // Check ties below
    const tiesBelowResult = getResult({ answer }, tiesCount - 1)
    if (tiesBelowResult.resident != resident) {
        closeSituations.push(`lose a tie to the UK`)
    }

    let closeSituationsContent

    let currentSituation = resident ? 'resident' : 'non-resident'
    let nearbySituation = !resident ? 'resident' : 'non-resident'

    if (closeSituations.length == 0) {
        closeSituationsContent = (
            <p>
                You would need to considerably change your situation to change from a UK{' '}
                <span className={classes('emphasis')}>{currentSituation}</span> to a{' '}
                <span className={classes('emphasis')}>{nearbySituation}</span>.
            </p>
        )
    } else if (closeSituations.length == 1) {
        closeSituationsContent = (
            <p>
                If you were to <span className={classes('emphasis')}>{closeSituations[0]}</span>, your residency status
                would change from a <span className={classes('emphasis')}>{currentSituation}</span> to a{' '}
                <span className={classes('emphasis')}>{nearbySituation}</span>.
            </p>
        )
    } else {
        closeSituationsContent = (
            <p>
                If you were to <span className={classes('emphasis')}>{closeSituations[0]}</span> or{' '}
                <span className={classes('emphasis')}>{closeSituations[1]}</span>, your residency status would change
                from a <span className={classes('emphasis')}>{currentSituation}</span> to a{' '}
                <span className={classes('emphasis')}>{nearbySituation}</span>.
            </p>
        )
    }

    return (
        <>
            <p>
                As you were in the UK for <span className={classes('emphasis')}>{dayLabels[daysIndex]}</span> days with{' '}
                <span className={classes('emphasis')}>{tiesCount}</span> UK ties, you{' '}
                <span className={classes('emphasis')}>{resident ? 'were' : 'were not'}</span> a UK resident for tax
                purposes.
            </p>
            {closeSituationsContent}
        </>
    )
}

const getTiesTextHtml = (answer, resident) => {
    if (!answer.ties) return null
    const tiesCount = countTies(answer)
    const days = answer.daysInUK

    const daysIndex = dayKeys.indexOf(days)

    let closeSituations = []

    // Check days above
    const daysAboveResult = getResult({ answer: { ...answer, daysInUK: dayKeys[daysIndex - 1] } }, tiesCount)
    if (daysAboveResult.resident != resident) {
        closeSituations.push(`spend ${dayLabels[daysIndex - 1]} days in the UK`)
    }
    // Check days below
    const daysBelowResult = getResult({ answer: { ...answer, daysInUK: dayKeys[daysIndex + 1] } }, tiesCount)
    if (daysBelowResult.resident != resident) {
        closeSituations.push(`spend ${dayLabels[daysIndex + 1]} days in the UK`)
    }
    // Check ties above
    const tiesAboveResult = getResult({ answer }, tiesCount + 1)
    if (tiesAboveResult.resident != resident) {
        closeSituations.push(`gain another tie to the UK`)
    }
    // Check ties below
    const tiesBelowResult = getResult({ answer }, tiesCount - 1)
    if (tiesBelowResult.resident != resident) {
        closeSituations.push(`lose a tie to the UK`)
    }

    let closeSituationsContent

    let currentSituation = resident ? 'resident' : 'non-resident'
    let nearbySituation = !resident ? 'resident' : 'non-resident'

    if (closeSituations.length == 0) {
        closeSituationsContent = `<p>
                You would need to considerably change your situation to change from a UK ${currentSituation} to a ${nearbySituation}.
            </p>`
    } else if (closeSituations.length == 1) {
        closeSituationsContent = `<p>
                If you were to ${closeSituations[0]}, your residency status would change from a ${currentSituation} to a ${nearbySituation}.
            </p>`
    } else {
        closeSituationsContent = `<p>
                If you were to ${closeSituations[0]} or ${closeSituations[1]}, your residency status would change from a ${currentSituation} to a ${nearbySituation}.
            </p>`
    }

    return `<p>
                As you were in the UK for ${dayLabels[daysIndex]} days with ${tiesCount} UK ties, you ${
        resident ? 'were' : 'were not'
    } a UK resident for tax purposes.
            </p>
            ${closeSituationsContent}`
}

const organiseResults = (answers) => {
    let answerDetails = []
    Object.entries(questions).forEach(([key, value]) => {
        if (answers[key] == null) return
        let formattedAnswer

        switch (value.type) {
            case 'Single Choice':
                formattedAnswer = value.options.find((choice) => choice.key === answers[key]).name
                break
            case 'Boolean':
                formattedAnswer = answers[key] ? 'Yes' : 'No'
                break
            case 'Ties':
                value.title = 'Number of UK ties'
                formattedAnswer = countTies(answers)
                break
        }

        let taxYear = questions.taxYear.options.find((option) => option.key == answers['taxYear'])
        value.title = value.title.replace('{tax-year}', taxYear.name)

        answerDetails.push({
            question: value.title,
            answer: formattedAnswer,
        })
    })
    return answerDetails
}

module.exports = { getResult, countTies, getTiesText, getTiesTextHtml, organiseResults }
