'use client'

import styles from './LargeContentPanel.module.scss'
import classNames from 'classnames/bind'
import SectionWrap from '@/components/Layout/SectionWrap/SectionWrap'
import Button from '@/components/Links/Button/Button'
import Media from '@/components/Layout/Media/Media'
import Icon from '@/components/Elements/Icon/Icon'

import useIntersectionObserver from '@/components/Helpers/UseIntersectionObserver/UseIntersectionObserver'

import { useRef, useState, useEffect } from 'react'

const classes = classNames.bind(styles)

const LargeContentPanel = ({ attributes }) => {
    const { headerIcon, title, subtext, buttons, bulletPoints, image, side, styling } = attributes

    const sectionRef = useRef(null)
    const imageRef = useRef(null)
    const buttonsRef = useRef([])

    const [showIconHeader, setShowIconHeader] = useState(false)
    const [showBulletPoints, setShowBulletPoints] = useState(false)

    useIntersectionObserver({
        refs: [sectionRef],
        callback: ({ isIntersecting }) => {
            if (isIntersecting) {
                imageRef.current.classList.add(classes('enter'))
                buttonsRef.current.forEach((ref, i) => {
                    setTimeout(() => {
                        ref.classList.add(classes('enter'))
                    }, 250 * i)
                })

                setTimeout(() => {
                    setShowIconHeader(true)
                }, 300)

                setTimeout(() => {
                    setShowBulletPoints(true)
                }, 1100)
            }
        },
        options: {
            // a threshold of 0 will trigger our IntersectionObserver at the
            // base of the element – a threshold of 1 would trigger at the top.
            threshold: [0.2],
        },
    })

    return (
        <SectionWrap styling={styling} className={classes('wrapper', side)} ref={sectionRef}>
            <div className={classes('text-wrapper')}>
                <div className={classes('header-wrapper')}>
                    <div className={classes('icon-wrapper', { enter: showIconHeader })}>
                        <Icon icon={headerIcon} className={classes('icon')} />
                    </div>
                    {title && <h2 className={classes('title', { enter: showIconHeader })}>{title}</h2>}
                    {subtext && <span className={classes('description', { enter: showIconHeader })}>{subtext}</span>}
                </div>
                {bulletPoints &&
                    bulletPoints.map(({ id, text, icon }, i) => (
                        <BulletPointIcon text={text} key={id} icon={icon} visible={showBulletPoints} index={i} />
                    ))}
                {buttons && (
                    <div className={classes('buttons')}>
                        {buttons.map((button, i) => (
                            <Button
                                {...button}
                                key={button.id}
                                extraClasses={classes('button', { 'text-only': button?.type == 'text' })}
                                ref={(el) => (buttonsRef.current[i] = el)}
                            />
                        ))}
                    </div>
                )}
            </div>
            <div className={classes('image-background')}>
                {image?.data && (
                    <div className={classes('image-wrap')} ref={imageRef}>
                        <Media className={classes('image')} attributes={image.data.attributes} />
                    </div>
                )}
            </div>
        </SectionWrap>
    )
}

const BulletPointIcon = ({ icon, text, visible, index }) => {
    if (!icon) return null
    const ref = useRef(null)

    useEffect(() => {
        if (visible) {
            setTimeout(() => {
                ref.current.classList.add(classes('enter'))
            }, 50 * index)
        }
    }, [visible])

    return (
        <div className={classes('icon-text-box')} ref={ref}>
            <Icon icon={icon} className={classes('bullet-icon')} />
            <span>{text}</span>
        </div>
    )
}

export default LargeContentPanel
