'use client'
import React, { useState, useRef, useEffect } from 'react'
import AnimateHeight from 'react-animate-height'

import { useCustomContext } from '../CalculatorContext'

import ChevronDown from '@/icons/chevron-down.svg'
import Check from '@/icons/check.svg'

import styles from './MultipleDropdown.module.scss'
import classNames from 'classnames/bind'
const classes = classNames.bind(styles)

export default function MultipleDropdown({ options, title }) {
    const { state, dispatch } = useCustomContext()

    const dropDownRef = useRef(null)

    const [showDropdown, setShowDropdown] = useState(false)
    const [selected, setSelected] = useState(state.enabledBoxes.filter((box) => options.find(({ key }) => box == key)))

    const updateState = (key) => {
        if (selected.includes(key)) {
            setSelected(selected.filter((selectedKey) => selectedKey != key))
            return
        }
        setSelected([...selected, key])
    }

    const selectAll = () => {
        setSelected(options.map(({ key }) => key))
    }

    const clearState = () => {
        setSelected([])
    }

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropDownRef.current && !dropDownRef.current.contains(event.target)) {
                setShowDropdown(false)
            }
        }

        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [])

    return (
        <div className={classes('wrapper')} ref={dropDownRef}>
            <span className={classes('title')}>{title}</span>
            <div
                className={classes('selection-box', { active: showDropdown })}
                onClick={() => {
                    setShowDropdown(!showDropdown)
                }}
            >
                <span className={classes('selected-list')}>{getDropdownContent(options, selected)}</span>
                <ChevronDown />
            </div>
            <AnimateHeight
                height={showDropdown ? 'auto' : 0}
                className={classes('dropdown-wrapper', { active: showDropdown })}
                contentClassName={classes('dropdown-content')}
                animateOpacity={true}
                applyInlineTransitions={false}
            >
                <div className={classes('dropdown-controls')}>
                    <span className={classes('control')} onClick={() => selectAll()}>
                        Select all
                    </span>
                    <span className={classes('control')} onClick={() => clearState()}>
                        Clear
                    </span>
                </div>
                <div className={classes('options')}>
                    {options.map((choice, index) => (
                        <div key={index} className={classes('option')}>
                            <input
                                id={choice.key}
                                type={'checkbox'}
                                className={classes('checkbox')}
                                checked={selected.includes(choice.key)}
                                onChange={(e) => updateState(choice.key)}
                            />
                            <label htmlFor={choice.key} className={classes('label')}>
                                <div className={classes('fake-checkbox')}>
                                    <Check />
                                </div>
                                <span className={classes('option-title')}>{choice.name}</span>
                            </label>
                        </div>
                    ))}
                </div>
                <div className={classes('bottom-box')}>
                    <span className={classes('count')}>
                        {selected.length}/{options.length}
                    </span>
                    <div className={classes('button-wrap')}>
                        <div className={classes('button', 'cancel-button')} onClick={() => setShowDropdown(false)}>
                            Cancel
                        </div>
                        <div
                            className={classes('button', 'submit-button')}
                            onClick={() => {
                                let unselectedBoxes = options
                                    .filter(({ key }) => !selected.includes(key))
                                    .map(({ key }) => key)

                                dispatch({
                                    type: 'UPDATE_DROPDOWN',
                                    payload: { selectedBoxes: selected, unselectedBoxes },
                                })
                                setShowDropdown(false)
                            }}
                        >
                            Done
                        </div>
                    </div>
                </div>
            </AnimateHeight>
        </div>
    )
}

// Get the string that we show inside the multiple dropdown.
const getDropdownContent = (options, selected) => {
    return selected.length == 0
        ? 'Select'
        : options
              .filter(({ key }) => selected.includes(key))
              .map(({ name }) => name)
              .join(', ')
}
