'use client'

import styles from './ImageSection.module.scss'
import classNames from 'classnames/bind'
import Title from '@/components/Elements/Title/Title'
import Media from '@/components/Layout/Media/Media'
import SectionWrap from '@/components/Layout/SectionWrap/SectionWrap'
import { useRef, useState } from 'react'

import useIntersectionObserver from '@/components/Helpers/UseIntersectionObserver/UseIntersectionObserver'

const classes = classNames.bind(styles)

const ImageSection = ({ attributes, index }) => {
    const { title, subtitle, description, image, imageDesc } = attributes
    const sectionRef = useRef(null)

    useIntersectionObserver({
        refs: [sectionRef],
        callback: ({ isIntersecting }) => {
            if (isIntersecting) {
                sectionRef.current.classList.add(classes('enter'))
            }
        },
        options: {
            // a threshold of 0 will trigger our IntersectionObserver at the
            // base of the element – a threshold of 1 would trigger at the top.
            threshold: [0.2],
        },
    })

    return (
        <SectionWrap className={classes('section-wrap')} ref={sectionRef} id="contact-us">
            {attributes.title && (
                <div style={{ position: 'relative' }}>
                    <Title
                        title={title}
                        description={description}
                        subtitle={subtitle}
                        index={index}
                        gradient={index == 0}
                        className={classes('section-title')}
                    />
                </div>
            )}
            <Media attributes={image.data.attributes} />
            {imageDesc && <p className={classes('image-desc')}>{imageDesc}</p>}
        </SectionWrap>
    )
}
export default ImageSection
