import { useRef } from 'react'

import { getTiesTextHtml, organiseResults } from './ResidenceCalculatorUtils'

import Modal from '@/components/Elements/Modal/Modal'

import Check from '@/icons/check.svg'
import Close from '@/icons/x.svg'

import styles from './ResidenceEmailPopup.module.scss'
import classNames from 'classnames/bind'

const classes = classNames.bind(styles)

export default function ResidenceEmailPopup({ open, setOpen, resident, answer }) {
    const emailRef = useRef(null)
    const newsletterRef = useRef(null)

    return (
        <Modal open={open} setOpen={setOpen} className={classes('modal')}>
            <div
                src={resident ? '/images/resident-graphic.png' : '/images/non-resident-graphic.png'}
                alt="residency-result-graphic"
                className={classes('graphic', { resident })}
            />
            <div className={classes('content-wrap')}>
                <h2>Get your results</h2>
                <p>
                    We'll send you the residency test results along with some valuable tips on tax residency. Don't
                    worry, we won't you send you any other emails unless you opt-in below too.
                </p>
                <form
                    className={classes('email-form')}
                    onSubmit={async (e) => {
                        e.preventDefault()

                        const email = emailRef.current.value
                        const newsletter = newsletterRef.current.checked

                        const fetchAPI = (await import('@/utils/strapiApi.js')).default
                        const response = await fetchAPI(
                            '/email-results',
                            {},
                            {
                                method: 'POST',
                                body: JSON.stringify({
                                    email,
                                    newsletter,
                                    resident,
                                    answer: organiseResults(answer),
                                    tiesText: getTiesTextHtml(answer, resident),
                                    threeYears: answer.lastThreeYears,
                                }),
                            }
                        )

                        if (response.success) {
                            setOpen(false)
                            return
                        }
                        alert(response.message)
                    }}
                >
                    <div className={classes('send-wrap')}>
                        <input type="email" placeholder="Your email" ref={emailRef} />
                        <button className={classes('button')}>Send</button>
                    </div>
                    <div>
                        <input type="checkbox" id={classes('newsletter')} ref={newsletterRef} />
                        <label htmlFor={classes('newsletter')} className={classes('newsletter-label')}>
                            <div className={classes('newsletter-check')}>
                                <Check />
                            </div>
                            <span className={classes('newsletter-text')}>
                                We'd love to keep you informed! Please tick this box to receive our tax insights and
                                updates.
                            </span>
                        </label>
                    </div>
                </form>
            </div>
            <Close className={classes('close')} onClick={() => setOpen(false)} />
        </Modal>
    )
}
