const { useRef, useState } = require('react')

import useIntersectionObserver from '@/components/Helpers/UseIntersectionObserver/UseIntersectionObserver'
import AnimateHeight from 'react-animate-height'

import HMRCIcon from '@/icons/hmrc.svg'
import Phone from '@/icons/phone.svg'
import PrimaryLogo from '@/images/logo/logo-primary.svg'
import Icon from '@/components/Elements/Icon/Icon'

import Dash from '@/icons/dash.svg'
import CheckHollow from '@/icons/check-circle-hollow.svg'
import CheckDark from '@/icons/check-circle-dark.svg'

import styles from './ComparisonMobile.module.scss'
import classNames from 'classnames/bind'

const classes = classNames.bind(styles)

const ComparisonMobile = ({ data }) => {
    return (
        <div className={classes('wrapper')}>
            {data.map((item, index) => (
                <Feature key={index} {...item} />
            ))}
        </div>
    )
}

const Feature = ({ title, description, values }) => {
    const sectionRef = useRef(null)
    const comparisonRef = useRef(null)
    const [expand, setExpand] = useState(false)

    useIntersectionObserver({
        refs: [sectionRef],
        callback: ({ isIntersecting }) => {
            if (isIntersecting) {
                setTimeout(() => {
                    sectionRef.current.classList.add(classes('enter'))
                }, 100)
            }
        },
        options: {
            // a threshold of 0 will trigger our IntersectionObserver at the
            // base of the element – a threshold of 1 would trigger at the top.
            threshold: [0.2],
        },
    })

    return (
        <div className={classes('feature')} ref={sectionRef}>
            <div
                style={{ display: `${!expand ? 'block' : 'flex'}` }}
                className={classes('header')}
                onClick={() => setExpand(!expand)}
            >
                <div className={classes('title-wrap')}>
                    <span>{title}</span>
                    {expand ? <Icon icon={'chevron-up'} /> : <Icon icon={'chevron-down'} />}
                </div>
                <AnimateHeight
                    duration={300}
                    height={expand ? 'auto' : 0}
                    easing={'ease-out'}
                    applyInlineTransitions={true}
                    animateOpacity={true}
                    ref={comparisonRef}
                >
                    <p>{description}</p>
                </AnimateHeight>
            </div>
            <div className={classes('values')}>
                <div className={classes('value', 'taxd')}>
                    <div className={classes('item')}>
                        <PrimaryLogo className={classes('item-logo')} />
                    </div>
                    <div className={classes('status')}>{values.taxd ? <CheckDark /> : <Dash />}</div>
                </div>
                <div className={classes('value')}>
                    <div className={classes('item')}>
                        <HMRCIcon className={classes('item-icon')} />
                        <span>HMRC</span>
                    </div>
                    <div className={classes('status')}>{values.hmrc ? <CheckHollow /> : <Dash />}</div>
                </div>
                <div className={classes('value')}>
                    <div className={classes('item')}>
                        <Phone className={classes('item-icon')} />
                        <span>Accountant</span>
                    </div>
                    <div className={classes('status')}>{values.accountant ? <CheckHollow /> : <Dash />}</div>
                </div>
            </div>
        </div>
    )
}

export default ComparisonMobile
