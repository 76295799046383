'use client'

import styles from './ContentWithImage.module.scss'
import classNames from 'classnames/bind'
import Button from '@/components/Links/Button/Button'
import Markdown from 'markdown-to-jsx'
import SectionWrap from '@/components/Layout/SectionWrap/SectionWrap'
import Media from '@/components/Layout/Media/Media'
import BlueIconBox from '@/components/Elements/BlueIconBox/BlueIconBox'

import useIntersectionObserver from '@/components/Helpers/UseIntersectionObserver/UseIntersectionObserver'

import { useRef, useState } from 'react'

const classes = classNames.bind(styles)

const ContentWithImage = ({ attributes }) => {
    const { title, description, image, imageSide, buttons, styling, blueIconBox } = attributes

    const sectionRef = useRef(null)
    const titleRef = useRef(null)
    const descriptionRef = useRef(null)
    const imageRef = useRef(null)
    const buttonsRef = useRef([])

    const [showBlueBox, setShowBlueBox] = useState(false)

    useIntersectionObserver({
        refs: [sectionRef],
        callback: ({ isIntersecting }) => {
            if (isIntersecting) {
                titleRef.current.classList.add(classes('enter'))
                descriptionRef.current.classList.add(classes('enter'))
                imageRef.current.classList.add(classes('enter'))
                setShowBlueBox(true)
                buttonsRef.current.forEach((ref, i) => {
                    setTimeout(() => {
                        ref.classList.add(classes('enter'))
                    }, 250 * i)
                })
            }
        },
        options: {
            // a threshold of 0 will trigger our IntersectionObserver at the
            // base of the element – a threshold of 1 would trigger at the top.
            threshold: [0.2],
        },
    })

    return (
        <SectionWrap styling={styling} className={classes('wrapper', imageSide)} ref={sectionRef}>
            <div className={classes('text-wrapper')}>
                <h1 className={classes('title')} ref={titleRef}>
                    {title}
                </h1>
                <div className={classes('description')} ref={descriptionRef}>
                    <Markdown children={description || ''} />
                </div>
                {blueIconBox && (
                    <BlueIconBox {...blueIconBox} className={classes('content-box')} visible={showBlueBox} />
                )}

                {buttons && (
                    <div className={classes('buttons')}>
                        {buttons.map((button, i) => (
                            <Button
                                {...button}
                                extraClasses={classes('button')}
                                key={button.id}
                                ref={(el) => (buttonsRef.current[i] = el)}
                            />
                        ))}
                    </div>
                )}
            </div>
            <div className={classes('image-background')} ref={imageRef}>
                {image?.data && <Media className={classes('image')} attributes={image.data.attributes} fill={true} />}
            </div>
        </SectionWrap>
    )
}
export default ContentWithImage
