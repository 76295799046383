import { useReducer, createContext, useContext } from 'react'
import { addToObject, deleteFromObject, getValue } from '@/utils/index'

import calculatorTypes from './calculator-types.json'
import calculatorConfig from './config.json'

const CustomContext = createContext()

const CalculatorProvider = ({ children, type }) => {
    // Set the initial state
    const [state, dispatch] = useReducer(Reducer, {
        calculatorKey: type,
        config: calculatorTypes[type],
        results: {},
        submitButtonEnabled: false,
        enabledBoxes: calculatorTypes[type]['enabled_boxes'],
        summaryBoxes: calculatorTypes[type]['summary_boxes'],
        answer: {
            'tax-year': '__2023-24',
        },
    })

    return (
        <CustomContext.Provider
            value={{
                state,
                dispatch,
            }}
        >
            {state.config && children}
        </CustomContext.Provider>
    )
}

const Reducer = (state, action) => {
    const { type, payload } = action

    // Switch statement to handle the type of function the reducer will call
    switch (type) {
        case 'UPDATE_DROPDOWN':
            // Gets order of the keys from config
            const keyOrder = Object.keys(calculatorConfig)
            // Gets the enabled boxes from the payload
            const newEnabledBoxes = Array.from(
                new Set([
                    ...state.enabledBoxes.filter((section) => !payload.unselectedBoxes?.includes(section)),
                    ...payload.selectedBoxes,
                ])
            ).sort((a, b) => keyOrder.indexOf(a) - keyOrder.indexOf(b))

            // Gets the summary boxes from the payload
            const newSummaryBoxes = Array.from(
                new Set(newEnabledBoxes.map((key) => calculatorConfig[key].summaryKeys).flat())
            ).sort((a, b) => keyOrder.indexOf(a) - keyOrder.indexOf(b))

            return {
                ...state,
                enabledBoxes: newEnabledBoxes,
                summaryBoxes: newSummaryBoxes,
            }
        case 'RESET_ANSWERS':
            // Resets the answer object to the initial state with just the tax year
            return { ...state, results: {}, answer: { 'tax-year': state.answer['tax-year'] } }
        case 'SET_ANSWER':
            // Adds the answer to the answer object of the state
            let tempAnswer = { ...state.answer }
            if (payload.value === '') {
                deleteFromObject(tempAnswer, getValue(payload, 'position', null), payload.key)
            } else {
                addToObject(tempAnswer, getValue(payload, 'position', null), payload.key, payload.value)
            }
            return { ...state, answer: tempAnswer, results: {} }
        case 'SET_SUBMIT_BUTTON_ENABLED':
            // Sets the submit button to enabled or disabled
            state = { ...state, submitButtonEnabled: payload }
        case 'SET_RESULTS':
            // Sets the results object to the payload
            return { ...state, results: payload }
    }
}

const useCustomContext = () => {
    const context = useContext(CustomContext)
    if (context === undefined) {
        throw new Error('useCustomContext must be used within a CustomContext')
    }

    return context
}

export { CalculatorProvider, useCustomContext }
