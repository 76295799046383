'use client'
import React, { useState, useEffect, use } from 'react'
import Slider from 'react-slider'

import Button from '@/components/Links/Button/Button'

import Check from '@/icons/check-icon.svg'
import Tick from '@/icons/check.svg'

import styles from './CalculatorQuestions.module.scss'
import classNames from 'classnames/bind'

const classes = classNames.bind(styles)

const CalculatorQuestions = ({}) => {
    const [selected, setSelected] = useState(true)
    const [hourlyRate, setHourlyRate] = useState(30)
    const [hoursSpent, setHoursSpent] = useState(5)
    const [additionalFees, setAdditionalFees] = useState(0)
    const [accountantFees, setAccountantFees] = useState(150)
    const [summaryText, setSummaryText] = useState('')

    const [totalSavings, setTotalSavings] = useState()

    useEffect(() => {
        setHourlyRate(30)
        setHoursSpent(5)
        setAdditionalFees(0)
        setAccountantFees(150)
    }, [selected])

    useEffect(() => {
        if (selected) {
            setTotalSavings(hourlyRate * hoursSpent + additionalFees - 90)
        } else {
            setTotalSavings(accountantFees - 90)
        }
        setSummaryText(
            totalSavings < 0
                ? `Ah! While immediate savings aren't guaranteed, our software and team of accountants can provide peace of mind, accurate compliance, and potential tax efficiencies to reduce your overall tax bill.`
                : `As well as saving on accountancy fees and time, we’ll  guide you through further tax savings too. Accurate compliance and expert support will take that stress away too.`
        )
    }, [selected, hourlyRate, hoursSpent, additionalFees, accountantFees])

    return (
        <div className={classes('container')}>
            <div className={classes('header')}>
                <span className={classes('subtitle')}>Calculator</span>
                <span className={classes('title')}>How much does Taxd save you?</span>
            </div>
            <div className={classes('box')}>
                <div className={classes('questions-wrap')}>
                    <span className={classes('intro')}>
                        Fill in the questions below and see specific money & time amount you will save using Taxd for
                        submitting your taxations
                    </span>
                    <div className={classes('question-container')}>
                        <span>1. Do you fill taxes on your own?</span>

                        <div className={classes('radio-group')}>
                            <div>
                                <input
                                    id={'yes'}
                                    value={'yes'}
                                    type={'radio'}
                                    name={'filing'}
                                    className={classes('radio')}
                                    checked={selected}
                                    onChange={() => setSelected(true)}
                                />
                                <label htmlFor={'yes'} className={classes('radio-container')}>
                                    <div className={classes('svg-container')}>
                                        <Tick />
                                    </div>
                                    Yes, I do
                                </label>
                            </div>
                            <div>
                                <input
                                    id={'no'}
                                    value={'no'}
                                    type={'radio'}
                                    name={'filing'}
                                    className={classes('radio')}
                                    checked={!selected}
                                    onChange={() => setSelected(false)}
                                />
                                <label htmlFor={'no'} className={classes('radio-container')}>
                                    <div className={classes('svg-container')}>
                                        <Tick />
                                    </div>
                                    No, I use an accountant
                                </label>
                            </div>
                        </div>
                    </div>
                    {selected ? (
                        <>
                            <div className={classes('question-container')}>
                                <span>2. Select how much is an hour worth to you:</span>
                                <Slider
                                    ariaLabel={'£'}
                                    className={classes('slider')}
                                    markClassName={classes('mark')}
                                    min={0}
                                    max={10}
                                    step={1}
                                    pearling={true}
                                    thumbClassName={classes('thumb')}
                                    thumbActiveClassName={classes('thumb', 'thumb-active')}
                                    renderMark={(props) => {
                                        let key = props.key
                                        delete props.key
                                        return (
                                            <div {...props} key={key}>
                                                <div className={classes('mark-line')}></div>
                                            </div>
                                        )
                                    }}
                                    renderThumb={(props, state) => {
                                        let key = props.key
                                        delete props.key
                                        return (
                                            <div {...props} key={key}>
                                                <ValueContainer value={10 * state.valueNow} symbol={'£'} />
                                            </div>
                                        )
                                    }}
                                    renderTrack={(props, state) => {
                                        let key = props.key
                                        delete props.key
                                        return (
                                            <div
                                                key={key}
                                                {...props}
                                                className={classes('track', `track-${state.index}`)}
                                            ></div>
                                        )
                                    }}
                                    marks
                                    value={hourlyRate / 10}
                                    onChange={(value) => setHourlyRate(value * 10)}
                                />
                            </div>
                            <div className={classes('question-container')}>
                                <span>3. How many hours do you spend on your self-assessment:</span>
                                <Slider
                                    className={classes('slider')}
                                    markClassName={classes('mark')}
                                    min={0}
                                    max={10}
                                    step={1}
                                    pearling={true}
                                    thumbClassName={classes('thumb')}
                                    thumbActiveClassName={classes('thumb', 'thumb-active')}
                                    renderMark={(props) => {
                                        let key = props.key
                                        delete props.key
                                        return (
                                            <div {...props} key={key}>
                                                <div className={classes('mark-line')}></div>
                                            </div>
                                        )
                                    }}
                                    renderThumb={(props, state) => {
                                        let key = props.key
                                        delete props.key
                                        return (
                                            <div {...props} key={key}>
                                                <ValueContainer value={state.valueNow} />
                                            </div>
                                        )
                                    }}
                                    renderTrack={(props, state) => {
                                        let key = props.key
                                        delete props.key
                                        return (
                                            <div
                                                key={key}
                                                {...props}
                                                className={classes('track', `track-${state.index}`)}
                                            ></div>
                                        )
                                    }}
                                    marks
                                    value={hoursSpent}
                                    onChange={(value) => setHoursSpent(value)}
                                />
                            </div>
                            <div className={classes('question-container')}>
                                <span>4. Additional fees e.g. postage:</span>
                                <Slider
                                    ariaLabel={'£'}
                                    className={classes('slider')}
                                    markClassName={classes('mark')}
                                    min={0}
                                    max={10}
                                    step={1}
                                    pearling={true}
                                    thumbClassName={classes('thumb')}
                                    thumbActiveClassName={classes('thumb', 'thumb-active')}
                                    renderMark={(props) => {
                                        let key = props.key
                                        delete props.key
                                        return (
                                            <div {...props} key={key}>
                                                <div className={classes('mark-line')}></div>
                                            </div>
                                        )
                                    }}
                                    renderThumb={(props, state) => {
                                        let key = props.key
                                        delete props.key
                                        return (
                                            <div {...props} key={key}>
                                                <ValueContainer value={10 * state.valueNow} symbol={'£'} />
                                            </div>
                                        )
                                    }}
                                    renderTrack={(props, state) => {
                                        let key = props.key
                                        delete props.key
                                        return (
                                            <div
                                                key={key}
                                                {...props}
                                                className={classes('track', `track-${state.index}`)}
                                            ></div>
                                        )
                                    }}
                                    marks
                                    value={additionalFees / 10}
                                    onChange={(value) => setAdditionalFees(value * 10)}
                                />
                            </div>
                        </>
                    ) : (
                        <div className={classes('question-container')}>
                            <span>2. How much does your accountant charge you:</span>
                            <Slider
                                ariaLabel={'£'}
                                className={classes('slider')}
                                markClassName={classes('mark')}
                                min={0}
                                max={100}
                                step={1}
                                pearling={true}
                                thumbClassName={classes('thumb')}
                                thumbActiveClassName={classes('thumb', 'thumb-active')}
                                renderMark={(props) => {
                                    let key = props.key
                                    if (key % 5 != 0) return null
                                    delete props.key
                                    return (
                                        <div {...props} key={key}>
                                            <div className={classes('mark-line')}></div>
                                        </div>
                                    )
                                }}
                                renderThumb={(props, state) => {
                                    let key = props.key
                                    delete props.key
                                    return (
                                        <div {...props} key={key}>
                                            <ValueContainer value={10 * state.valueNow} symbol={'£'} />
                                        </div>
                                    )
                                }}
                                renderTrack={(props, state) => {
                                    let key = props.key
                                    delete props.key
                                    return (
                                        <div
                                            key={key}
                                            {...props}
                                            className={classes('track', `track-${state.index}`)}
                                        ></div>
                                    )
                                }}
                                marks
                                value={accountantFees / 10}
                                onChange={(value) => setAccountantFees(value * 10)}
                            />
                        </div>
                    )}
                </div>
                <div className={classes('quotation-wrap')}>
                    <div className={classes('pricing-wrapper')}>
                        <div className={classes('tag', { red: totalSavings < 0 })}>
                            <span>Total estimated savings</span>
                        </div>
                        <div className={classes('price-wrap')}>
                            <span className={classes('price', { red: totalSavings < 0 })}>£{totalSavings}</span>
                            <div className={classes('savings-wrap')}>
                                <span className={classes('savings', { red: totalSavings < 0 })}>/savings</span>
                            </div>
                        </div>
                        <p className={classes('summary-text')}>{summaryText}</p>
                    </div>
                    <div className={classes('separator')} />
                    <div className={classes('perks')}>
                        <span className={classes('perks-title')}>You also gain:</span>
                        <div className={classes('perk')}>
                            <Check />
                            <span>Peace of mind</span>
                        </div>
                        <div className={classes('perk')}>
                            <Check />
                            <span>Time back in your day</span>
                        </div>
                        <div className={classes('perk')}>
                            <Check />
                            <span>Savings on your tax bill</span>
                        </div>
                    </div>
                    <div className={classes('separator')} />
                    <div className={classes('button-container')}>
                        <Button
                            type={'primary'}
                            text={'Get started'}
                            extraClasses={classes('get-started')}
                            url={'https://assessment.taxd.co.uk/'}
                            newTab={true}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

const ValueContainer = ({ value, symbol }) => (
    <div className={classes('value-container')}>
        <span className={classes('value')}>
            {symbol} {value}
        </span>
    </div>
)

export default CalculatorQuestions
