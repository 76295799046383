'use client'

import { useRef } from 'react'
import useIntersectionObserver from '@/components/Helpers/UseIntersectionObserver/UseIntersectionObserver'

import Button from '@/components/Links/Button/Button'
import Markdown from 'markdown-to-jsx'

import styles from './ThreeStepSection.module.scss'
import classNames from 'classnames/bind'

const classes = classNames.bind(styles)

export default function ThreeStepSection({ attributes, onClick }) {
    const { title, description, points } = attributes

    const sectionRef = useRef(null)
    const pointsRef = useRef([])
    const buttonRef = useRef(null)
    const mobileButtonRef = useRef(null)

    useIntersectionObserver({
        refs: [sectionRef],
        callback: ({ isIntersecting }) => {
            if (isIntersecting) {
                sectionRef.current.classList.add(classes('enter'))
                setTimeout(() => {
                    pointsRef.current.forEach((ref, i) => {
                        setTimeout(() => {
                            ref.classList.add(classes('enter'))
                        }, 150 * i)
                    })
                }, 200)
                setTimeout(() => {
                    try {
                        buttonRef.current.classList.add(classes('enter'))
                        mobileButtonRef.current.classList.add(classes('enter'))
                    } catch (e) {}
                }, 700)
            }
        },
        options: {
            // a threshold of 0 will trigger our IntersectionObserver at the
            // base of the element – a threshold of 1 would trigger at the top.
            threshold: [0.2],
        },
    })

    return (
        <div className={classes('wrapper')} ref={sectionRef}>
            <div className={classes('left')}>
                <div className={classes('content')}>
                    <h2 className={classes('title')}>{title}</h2>
                    <div className={classes('description')}>
                        <Markdown children={description || ''} />
                    </div>
                </div>
                <Button
                    extraClasses={classes('button', 'desktop-button')}
                    type={'primary'}
                    text={'Get started'}
                    onClick={onClick}
                    ref={buttonRef}
                />
            </div>
            <div className={classes('right')}>
                {points.map((point, index) => (
                    <div className={classes('point')} key={index} ref={(el) => (pointsRef.current[index] = el)}>
                        <span className={classes('number')}>
                            <span>{index + 1}</span>
                        </span>
                        <div className={classes('point-wrap')}>
                            <span className={classes('point-title')}>{point.title}</span>
                            <p className={classes('point-text')}>{point.text}</p>
                        </div>
                    </div>
                ))}
            </div>
            <Button
                extraClasses={classes('button', 'mobile-button')}
                type={'primary'}
                text={'Get started'}
                onClick={onClick}
                ref={mobileButtonRef}
            />
        </div>
    )
}
