'use client'

import styles from './InfiniteSlider.module.scss'
import classNames from 'classnames/bind'
import SectionWrap from '@/components/Layout/SectionWrap/SectionWrap'
import Button from '@/components/Links/Button/Button'
import Icon from '@/components/Elements/Icon/Icon'
import useIntersectionObserver from '@/components/Helpers/UseIntersectionObserver/UseIntersectionObserver'

import React, { useRef, useEffect, useState } from 'react'

const classes = classNames.bind(styles)

const InfiniteSlider = ({ attributes }) => {
    const { id, title, buttons, sliderElements, styling } = attributes
    const sliderWrapper = useRef(null)
    const sliderOne = useRef(null)
    const sliderTwo = useRef(null)

    const [sliderWidth, setSliderWidth] = useState(0)

    useEffect(() => {
        setSliderWidth(sliderOne.current.offsetWidth)

        const resizeHandler = () => {
            setTimeout(() => {
                setSliderWidth(sliderOne.current.offsetWidth)
            }, 200)
        }

        window.addEventListener('resize', resizeHandler)

        return () => {
            window.removeEventListener('resize', resizeHandler)
        }
    }, [])

    useEffect(() => {
        const animation1 = [{ left: 0 }, { left: `${sliderWidth * -1}px` }]
        const animation2 = [{ left: `${sliderWidth - 1}px` }, { left: 0 }]

        const standardWidth = 1100
        const standardTime = 30000

        const animationTime = {
            duration: Math.max((window.innerWidth / standardWidth) * standardTime, 15000),
            iterations: Infinity,
        }

        sliderOne.current.animate(animation1, animationTime)
        sliderTwo.current.animate(animation2, animationTime)
    }, [sliderWidth])

    const sectionRef = useRef(null)
    const titleRef = useRef(null)
    const buttonsRef = useRef([])
    const sliderElementsRef = useRef([])

    useIntersectionObserver({
        refs: [sectionRef],
        callback: ({ isIntersecting }) => {
            if (isIntersecting) {
                titleRef.current.classList.add(classes('enter'))
                buttonsRef.current.forEach((ref, i) => {
                    setTimeout(() => {
                        ref.classList.add(classes('enter'))
                    }, 250 * i)
                })
                sliderElementsRef.current.forEach((ref, i) => {
                    setTimeout(() => {
                        ref.classList.add(classes('enter'))
                    }, 150 * i)
                })
                sliderTwo.current.classList.add(classes('enter'))
                setTimeout(() => {
                    sliderOne.current.classList.add(classes('entered'))
                    sliderTwo.current.classList.add(classes('entered'))
                }, 500)
            }
        },
        options: {
            // a threshold of 0 will trigger our IntersectionObserver at the
            // base of the element – a threshold of 1 would trigger at the top.
            threshold: [0.2],
        },
    })

    return (
        <SectionWrap styling={styling} className={classes('full-width')} ref={sectionRef}>
            <div className={classes('wrapper')}>
                <div ref={titleRef} className={classes('top-content')}>
                    <h2 className={classes('title')}>{title.title}</h2>
                    <p className={classes('description')}>{title.description}</p>
                </div>
                {buttons && (
                    <div className={classes('buttons')}>
                        {buttons.map((button, i) => (
                            <Button
                                {...button}
                                extraClasses={classes('button')}
                                key={button.id}
                                ref={(el) => (buttonsRef.current[i] = el)}
                            />
                        ))}
                    </div>
                )}
            </div>
            <div className={classes('slider-wrapper')} ref={sliderWrapper}>
                <div
                    className={classes('slider')}
                    ref={sliderOne}
                    onMouseLeave={() => {
                        sliderOne.current.style.transform = 'none'
                        sliderTwo.current.style.transform = 'none'
                    }}
                >
                    {sliderElements &&
                        sliderElements.map((item, i) => (
                            <SliderElement
                                {...item}
                                key={item.id}
                                first={true}
                                ref={(el) => (sliderElementsRef.current[i] = el)}
                            />
                        ))}
                </div>
                <div
                    className={classes('slider', 'second')}
                    ref={sliderTwo}
                    style={{ left: '100%' }}
                    onMouseLeave={() => {
                        sliderOne.current.style.transform = 'none'
                        sliderTwo.current.style.transform = 'none'
                    }}
                >
                    {sliderElements &&
                        sliderElements.map((item) => <SliderElement {...item} key={item.id + '-second'} />)}
                </div>
            </div>
        </SectionWrap>
    )
}

const SliderElement = React.forwardRef(({ id, title, description, icon, first = false }, ref) => {
    return (
        <div className={classes('slider-element', { hidden: first })} key={id} ref={ref}>
            <div className={classes('slider-image-wrap')}>{icon && <Icon icon={icon} />}</div>
            <span className={classes('slider-element-title')}>{title}</span>
        </div>
    )
})

export default InfiniteSlider
