import questions from './config.json'

import { useState } from 'react'

import { getResult, countTies, getTiesText } from './ResidenceCalculatorUtils'

import { useResidenceContext } from './ResidenceCalculatorContext'
import ResidenceTable from './ResidenceTable'
import ResidenceEmailPopup from './ResidenceEmailPopup'

import styles from './ResidenceCalculator.module.scss'
import classNames from 'classnames/bind'
const classes = classNames.bind(styles)

export default function ResultsPage() {
    const [emailModal, setEmailModal] = useState(false)
    const { state, dispatch } = useResidenceContext()
    if (!state.result) return null

    const tieCount = state.answer.ties ? countTies(state.answer) : 0
    const residencyResults = getResult(state, tieCount)

    const resident = residencyResults.resident

    let text = residencyResults.text
    if (residencyResults.key == 'ties') {
        text = resident ? residencyResults.residentText : residencyResults.nonResidentText
        text = text.replace('{count}', residencyResults.count)
    }

    return (
        <div className={classes('results-wrapper')}>
            <div className={classes('results-content')}>
                <div className={classes('results-text-wrap')}>
                    <h2 className={classes('question-title')}>{processTitle(resident, state.answer.taxYear)}</h2>
                    <div className={classes('results-graphic', 'mobile', { resident })} />
                    <div className={classes('description')} dangerouslySetInnerHTML={{ __html: text }} />
                    <hr className={classes('line')} />
                    <p className={classes('description', 'disclaimer')}>
                        Disclaimer: This tool offers guidance based on your input and isn't an official Taxd decision.
                        The SRT may not always determine your tax residence definitively. You could be a tax resident in
                        another country with its own rules, so consider their tests and review the relevant double tax
                        treaty with the UK if needed.
                    </p>
                    <div className={classes('navigation-buttons', 'results')}>
                        <button
                            className={classes('button', 'continue-button')}
                            onClick={(e) => {
                                e.preventDefault()
                                window.location.assign('https://intro.taxd.co.uk')
                            }}
                        >
                            Start tax return
                        </button>
                        <button
                            className={classes('button', 'email-button')}
                            onClick={(e) => {
                                e.preventDefault()
                                setEmailModal(true)
                            }}
                        >
                            Email results
                        </button>
                    </div>
                </div>
                <div className={classes('results-graphic', { resident })} />
            </div>
            <hr className={classes('line')} />
            {residencyResults.key == 'ties' && (
                <div className={classes('ties-section')}>
                    <div className={classes('ties-content')}>
                        <span className={classes('ties-header')}>UK Sufficient Ties Test</span>
                        <p>
                            To determine your UK residence status for the tax year when you do not meet any of the
                            automatic overseas or UK tests, you will need to apply the Sufficient Ties Test. This
                            involves evaluating your connections to the UK, referred to as "ties", in combination with
                            the number of days you spend in the UK during that tax year.
                        </p>
                        <br />
                        <p>The UK Ties:</p>
                        <ul>
                            <li>A family tie</li>
                            <li>An accommodation tie</li>
                            <li>A work tie</li>
                            <li>A 90-day tie</li>
                            <li>
                                A country tie (if you were a UK resident in one or more of the 3 tax years before the
                                one you are considering)
                            </li>
                        </ul>
                        <p>
                            Keep in mind that the more UK ties you have, the fewer days you can spend in the UK before
                            becoming a UK resident.
                        </p>
                        <p style={{ marginTop: '16px' }}>
                            Full details can be found by{' '}
                            <a
                                className={classes('link')}
                                href="https://cdn.taxd.co.uk/UK_Residence_Tests_d1b384aca6.pdf"
                                target="_blank"
                            >
                                clicking here
                            </a>
                            .
                        </p>
                        <span className={classes('ties-header')} style={{ marginTop: '32px' }}>
                            Your Ties
                        </span>
                        {state.answer.ties && getTiesText(state, resident)}
                    </div>
                    <ResidenceTable
                        threeYears={state.answer.lastThreeYears}
                        ties={countTies(state.answer)}
                        days={state.answer.daysInUK}
                    />
                </div>
            )}
            <div className={classes('social-section')}>
                <span className={classes('social-title')}>Did you find our test helpful?</span>
                <p className={classes('social-text')}>
                    Share with your friends and family so they can find their residency status too!
                </p>
                <div className={classes('social-icons')}>
                    <a
                        className={classes('social-icon')}
                        href={`https://api.whatsapp.com/send?text=Check%20out%20this%20residency%20calculator%20at%20${window.location.href}!%20I%20found%20it%20really%20helpful!`}
                        target="_blank"
                    >
                        <img src="/images/icons/whatsapp.svg" alt="whatsapp-icon" />
                    </a>
                    <a
                        className={classes('social-icon')}
                        href={`https://www.linkedin.com/shareArticle?mini=true&url=${window.location.href}`}
                        target="_blank"
                    >
                        <img src="/images/icons/linkedin.svg" alt="linkedin-icon" />
                    </a>
                    <a
                        className={classes('social-icon')}
                        href={`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`}
                        target="_blank"
                    >
                        <img src="/images/icons/facebook.svg" alt="facebook-icon" />
                    </a>
                    <a
                        className={classes('social-icon')}
                        href={`https://twitter.com/intent/tweet?text=Check%20out%20this%20helpful%20UK%20tax%20residency%20quiz!%20It%20really%20helped%20me%20out!%20${window.location.href}`}
                        target="_blank"
                    >
                        <img src="/images/icons/xsocial.svg" alt="x-social-icon" />
                    </a>
                </div>
            </div>
            <ResidenceEmailPopup open={emailModal} setOpen={setEmailModal} resident={resident} answer={state.answer} />
        </div>
    )
}

const processTitle = (resident, year) => {
    let ukResidentString = resident ? '' : ' not'

    const date = new Date(`20${year.slice(-2)}-04-06`)
    const string = date > new Date() ? `will${ukResidentString} be` : `were${ukResidentString}`
    const yearName = questions.taxYear.options.find((option) => option.key === year).name

    if (resident) {
        return `You ${string} a UK tax resident in ${yearName}`
    }
    return `You ${string} a UK tax resident in ${yearName}`
}
