'use client'

import styles from './Hero.module.scss'
import classNames from 'classnames/bind'

import SectionWrap from '@/components/Layout/SectionWrap/SectionWrap'
import useIntersectionObserver from '@/components/Helpers/UseIntersectionObserver/UseIntersectionObserver'
import Media from '@/components/Layout/Media/Media'
import Button from '@/components/Links/Button/Button'
import SmallIconText from '@/components/Elements/SmallIconText/SmallIconText'
import { useRef, useState } from 'react'

const classes = classNames.bind(styles)

const Hero = ({ attributes, gradient, index }) => {
    const { styling, title, description, picture, imageShadow, buttons, iconTextExtras } = attributes

    const sectionRef = useRef(null)
    const contentRef = useRef(null)
    const contentIconsRef = useRef(null)
    const imageRef = useRef(null)

    const [showSmallIconTexts, setShowSmallIconTexts] = useState(false)

    useIntersectionObserver({
        refs: [sectionRef],
        callback: ({ isIntersecting }) => {
            if (isIntersecting) {
                imageRef.current.classList.add(classes('enter'))
                contentRef.current.classList.add(classes('enter'))
                contentIconsRef.current.classList.add(classes('enter'))

                setTimeout(() => {
                    setShowSmallIconTexts(true)
                }, 200)
            }
        },
        options: {
            // a threshold of 0 will trigger our IntersectionObserver at the
            // base of the element – a threshold of 1 would trigger at the top.
            threshold: [0.1],
        },
    })

    return (
        <SectionWrap styling={styling} ref={sectionRef} className={classes('section-wrap')}>
            <div className={classes('wrapper', { 'first-element': index == 0 })}>
                <div className={classes('content')}>
                    <div ref={contentRef} className={classes('top-content')}>
                        <h1 className={classes('title')}>{title}</h1>
                        <p className={classes('description')}>{description}</p>
                    </div>
                    {gradient && <div id="gradient-end" style={{ marginTop: '25px', height: '1px' }} />}
                    <div ref={contentIconsRef} className={classes('bottom-content')}>
                        {buttons && (
                            <div className={classes('buttons')}>
                                {buttons.map((button) => (
                                    <Button
                                        {...button}
                                        key={button.id}
                                        extraClasses={classes({ 'text-only': button?.type == 'text' })}
                                    />
                                ))}
                            </div>
                        )}
                        {iconTextExtras && (
                            <div className={classes('icon-text-box-wrapper')}>
                                {iconTextExtras.map(({ id, text, image }, i) => (
                                    <SmallIconText
                                        text={text}
                                        key={id}
                                        index={i}
                                        attributes={image?.data?.attributes}
                                        visible={showSmallIconTexts}
                                    />
                                ))}
                            </div>
                        )}
                    </div>
                </div>
                <div className={classes('picture-wrapper')} ref={imageRef}>
                    {picture?.data && (
                        <Media
                            attributes={picture.data.attributes}
                            fill={true}
                            className={classes('picture', { 'image-shadow': imageShadow })}
                        />
                    )}
                </div>
            </div>
        </SectionWrap>
    )
}
export default Hero
