'use client'

import WindowSizeListener from '@/components/Helpers/WindowSizeListener/WindowSizeListener'

import styles from './PricingSection.module.scss'
import classNames from 'classnames/bind'

import MobilePricing from './MobilePricing'
import Pricing from './Pricing'

import SectionWrap from '@/components/Layout/SectionWrap/SectionWrap'
import Title from '@/components/Elements/Title/Title'

import PricingConfig from './config.json'

import { useState, useEffect, use } from 'react'

const classes = classNames.bind(styles)

const PricingSection = ({ attributes }) => {
    const { type, titleComponent } = attributes

    const allEnabled = type === 'all'

    let fullEnabledConfig = allEnabled
        ? PricingConfig.filter((config) => config.id !== 'landlord')
        : PricingConfig.filter((config) => type === config.id)

    const [selectedConfig, setSelectedConfig] = useState(fullEnabledConfig[0])
    const [mobile, setMobile] = useState(null)

    const updateSelectedConfig = (key) => {
        setSelectedConfig(fullEnabledConfig.find((config) => config.id === key))
    }

    useEffect(() => {
        WindowSizeListener('763px', setMobile)
    }, [])

    return (
        <SectionWrap
            id={'pricing-section'}
            className={classes('section-wrap', { mobile: mobile })}
            sectionClassName={classes('section')}
        >
            <div className={classes('title-wrap')}>
                <Title {...titleComponent} />
                {allEnabled && (
                    <TabNav
                        options={fullEnabledConfig.map(({ id, title }) => ({ id, title }))}
                        selectedId={selectedConfig.id}
                        updateSelectedConfig={updateSelectedConfig}
                    />
                )}
            </div>
            {mobile ? <MobilePricing config={selectedConfig?.mobile} /> : <Pricing config={selectedConfig?.desktop} />}
        </SectionWrap>
    )
}

export default PricingSection

const TabNav = ({ options, selectedId, updateSelectedConfig }) => (
    <div className={classes('tab-nav')}>
        {options.map(({ id, title }) => (
            <div
                key={id}
                className={classes('tab', { active: id === selectedId })}
                onClick={() => updateSelectedConfig(id)}
            >
                <span className={classes('tab-title')}>{title}</span>
            </div>
        ))}
    </div>
)
