'use client'

import { useRef, useState, useEffect, forwardRef } from 'react'
import useIntersectionObserver from '@/components/Helpers/UseIntersectionObserver/UseIntersectionObserver'
import AnimateHeight from 'react-animate-height'

import styles from './Review.module.scss'
import classNames from 'classnames/bind'
import Media from '@/components/Layout/Media/Media'

import Star from '@/icons/star.svg'
import Google from '@/icons/google.svg'

const classes = classNames.bind(styles)

const Review = ({ reviewer, comment, createTime, index = 1 }) => {
    const reviewRef = useRef(null)
    const commentRef = useRef(null)
    const hiddenRef = useRef(null)
    const boxRef = useRef(null)
    const [isHovered, setIsHovered] = useState(false)

    const [entered, setEntered] = useState(false)

    useIntersectionObserver({
        refs: [reviewRef],
        callback: ({ isIntersecting }) => {
            if (isIntersecting) {
                setTimeout(() => {
                    setEntered(true)
                }, index * 200)
            }
        },
        options: {
            // a threshold of 0 will trigger our IntersectionObserver at the
            // base of the element – a threshold of 1 would trigger at the top.
            threshold: [0.2],
        },
    })
    return (
        <div
            className={classes('wrapper', { hovered: isHovered, enter: entered })}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            ref={reviewRef}
        >
            <Content reviewer={reviewer} comment={comment} createTime={createTime} ref={commentRef} />
            <AnimateHeight
                height={isHovered ? 'auto' : 0}
                duration={100}
                easing={'ease-out'}
                applyInlineTransitions={true}
                animateOpacity={true}
                ref={boxRef}
            >
                <Content
                    reviewer={reviewer}
                    comment={comment}
                    createTime={createTime}
                    ref={hiddenRef}
                    isHovered={isHovered}
                    hidden
                />
            </AnimateHeight>
        </div>
    )
}

const Content = forwardRef(({ reviewer, comment, createTime, isHovered, hidden }, ref) => {
    return (
        <div className={classes('box', { 'hidden-box': hidden, visible: isHovered })}>
            <div className={classes('reviewer')}>
                <Media attributes={{ url: reviewer?.profilePhotoUrl, mime: 'jpg' }} width={40} height={40} />
                <div className={classes('reviewer-details')}>
                    <div className={classes('name')}>{reviewer?.displayName}</div>
                    <div className={classes('date')}>
                        {new Date(createTime).toLocaleDateString('en-GB', {
                            year: 'numeric',
                            month: 'short',
                            day: 'numeric',
                        })}
                    </div>
                </div>
                <div style={{ height: '100%' }}>
                    <Google className={classes('google')} />
                </div>
            </div>
            <div className={classes('stars')}>
                {[...Array(5)].map((_, i) => (
                    <Star key={i} className={classes('star')} />
                ))}
            </div>

            <div className={classes('comment', { full: hidden })} ref={ref}>
                {comment}
            </div>
        </div>
    )
})

export default Review
